import HomeView from '@/views/HomeView.vue'
import type { RouteRecordRaw } from 'vue-router'

const homeRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'home',
    meta: {
      headerConfig: {
        headerLabel: 'home',
        hide: false,
        showBack: false,
        showProfile: true,
      },
      footerConfig: {
        hide: false,
      },
      securityConfig: {
        requireAuthentication: true,
        rolesAllowed: ['common', 'admin'],
      },
      containerMargins: true,
    },
    component: HomeView,
  },
]

export default homeRoutes
