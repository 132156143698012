import PlannedMenuView from '@/views/Planning/PlannedMenuView.vue'
import PlanningView from '@/views/PlanningView.vue'
import type { RouteRecordRaw } from 'vue-router'

const planningRoutes: RouteRecordRaw[] = [
  {
    path: '/planning',
    name: 'planning',
    meta: {
      headerConfig: {
        headerLabel: 'planning',
        hide: false,
        showBack: false,
        showProfile: true,
      },
      footerConfig: {
        hide: false,
      },
      securityConfig: {
        requireAuthentication: true,
        rolesAllowed: ['common', 'admin'],
      },
      containerMargins: false,
    },
    component: PlanningView,
  },
  {
    path: '/planning/planned-menu/:id',
    meta: {
      headerConfig: {
        headerLabel: 'planning',
        hide: false,
        showBack: true,
        showProfile: false,
      },
      footerConfig: {
        hide: true,
      },
      securityConfig: {
        requireAuthentication: true,
        rolesAllowed: ['common', 'admin'],
      },
      containerMargins: true,
    },
    component: PlannedMenuView,
  },
]

export default planningRoutes
