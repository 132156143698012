<script setup lang="ts">
import InputNumber from 'primevue/inputnumber'
import { useAuth } from '@/composables/useAuth.js'
import { useFetch } from '@/composables/useFetch.js'
import type { MessageSchema } from '@/i18n/index.js'
import { useEnvStore } from '@/stores/envStore.js'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import { useToast } from 'primevue/usetoast'
import { onMounted, ref, type Ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { Supplier, SupplierTag } from '@/types/api.js'
import { useRouter } from 'vue-router'
import MultiSelect from 'primevue/multiselect'

const props = defineProps<{
  uuid?: string
}>()

const toast = useToast()
const router = useRouter()
const envStore = useEnvStore()
const { fetchData } = useFetch()
const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
const { user } = useAuth()

let isEdit = false

const name = ref('')
const tags: Ref<number[]> = ref([])
const email = ref('')
const phone = ref('')
const fax = ref('')
const website = ref('')
const address = ref('')
const city = ref('')
const postalCode = ref()

const allTags: Ref<SupplierTag[]> = ref([])

type ValidationErrors = {
  [key: string]: string[]
}

const errors: Ref<ValidationErrors> = ref({})

async function submitForm() {
  let url = envStore.apiUrl + '/restaurants/' + user.value?.restaurants[0].id + '/suppliers'
  let method: 'POST' | 'PATCH' = 'POST'

  if (isEdit) {
    url += '/' + props.uuid
    method = 'PATCH'
  }

  if (website.value && website.value !== '') {
    website.value = website.value.startsWith('https://')
      ? website.value
      : 'https://' + website.value.replace(/^http:\/\//, '')
  }

  const { error, data } = await fetchData(url, method, {
    name: name.value,
    email: email.value,
    phone: phone.value,
    fax: fax.value,
    website: website.value,
    address: address.value,
    city: city.value,
    postal_code: postalCode.value,
    tags: tags.value,
  })

  if (data) {
    toast.add({
      severity: 'success',
      summary: t('common.success'),
      detail: isEdit
        ? t('profile.suppliers.form.editSuccess')
        : t('profile.suppliers.form.creationSuccess'),
      life: 3000,
    })

    if (!isEdit) {
      router.replace({ name: 'supplier', params: { uuid: data.uuid } })
    } else {
      router.back()
    }
  } else if (error) {
    toast.add({
      severity: 'error',
      summary: t('common.error'),
      detail: isEdit
        ? t('profile.suppliers.form.editError')
        : t('profile.suppliers.form.creationError'),
      life: 3000,
    })

    if (error.details.errors) {
      errors.value = error.details.errors
      console.log(errors.value)
    }
  }
}

onMounted(() => {
  if (props.uuid) {
    isEdit = true

    fetchData(envStore.apiUrl + '/suppliers/' + props.uuid).then((data) => {
      if (data) {
        const supplier = data.data as Supplier
        name.value = supplier.name
        email.value = supplier.email ?? ''
        phone.value = supplier.phone ?? ''
        fax.value = supplier.fax ?? ''
        website.value = supplier.website ?? '' ?? ''
        address.value = supplier.address?.address_line_1 ?? ''
        city.value = supplier.address?.city.name ?? ''
        postalCode.value = supplier.address?.city.postal_code
        tags.value = supplier.tags.map((tag) => tag.id)
      }
    })
  }
  fetchData(envStore.apiUrl + '/suppliers/tags')
    .then((data) => {
      if (data) {
        allTags.value = data.data as SupplierTag[]
        allTags.value.forEach((tag) => {
          tag.name = t('profile.suppliers.tags.' + tag.name)
        })
        allTags.value.sort((a, b) => a.name.localeCompare(b.name))
      }
    })
    .catch((error) => {
      console.log(error)
    })
})
</script>

<template>
  <form class="flex flex-col gap-3" @submit.prevent="submitForm">
    <div>* {{ t('profile.suppliers.form.required') }}</div>
    <h3>{{ t('profile.suppliers.form.infos') }}</h3>
    <div class="flex flex-col">
      <label>{{ t('profile.suppliers.form.nameLabel') }} * </label>
      <InputText v-model="name" :placeholder="t('profile.suppliers.form.namePlaceholder')" />
      <p v-if="errors.name" class="text-sm text-red-600">{{ errors.name[0] }}</p>
    </div>

    <div class="flex flex-col">
      <label>{{ t('profile.suppliers.form.tagsLabel') }}</label>
      <MultiSelect
        v-model="tags"
        multiple
        filter
        :selectionLimit="3"
        display="chip"
        class="items-center w-full sm:text-sm h-9 sm:h-8"
        optionLabel="name"
        optionValue="id"
        :options="allTags"
        :virtualScrollerOptions="{ itemSize: 50 }"
        :placeholder="t('profile.suppliers.form.tagsPlaceholder')"
      />
      <p v-if="errors.tags" class="text-sm text-red-600">{{ errors.tags[0] }}</p>
    </div>

    <div class="flex flex-col">
      <label>{{ t('profile.suppliers.form.websiteLabel') }}</label>
      <InputText v-model="website" :placeholder="t('profile.suppliers.form.websitePlaceholder')" />
      <p v-if="errors.website" class="text-sm text-red-600">{{ errors.website[0] }}</p>
    </div>

    <h3 class="mt-4">{{ t('profile.suppliers.form.contact') }}</h3>
    <div class="flex flex-col">
      <label>{{ t('profile.suppliers.form.emailLabel') }}</label>
      <InputText v-model="email" :placeholder="t('profile.suppliers.form.emailPlaceholder')" />
      <p v-if="errors.email" class="text-sm text-red-600">{{ errors.email[0] }}</p>
    </div>

    <div class="flex flex-col">
      <label>{{ t('profile.suppliers.form.phoneLabel') }}</label>
      <InputText v-model="phone" :placeholder="t('profile.suppliers.form.phonePlaceholder')" />
      <p v-if="errors.phone" class="text-sm text-red-600">{{ errors.phone[0] }}</p>
    </div>

    <div class="flex flex-col">
      <label>{{ t('profile.suppliers.form.faxLabel') }}</label>
      <InputText v-model="fax" :placeholder="t('profile.suppliers.form.faxPlaceholder')" />
      <p v-if="errors.fax" class="text-sm text-red-600">{{ errors.fax[0] }}</p>
    </div>

    <h3 class="mt-4">{{ t('profile.suppliers.form.address') }}</h3>

    <div class="flex flex-col">
      <label>{{ t('profile.suppliers.form.addressLabel') }}</label>
      <InputText v-model="address" :placeholder="t('profile.suppliers.form.addressPlaceholder')" />
      <p v-if="errors.address" class="text-sm text-red-600">{{ errors.address[0] }}</p>
    </div>

    <div class="grid gap-4 sm:grid-cols-2 md:grid-cols-3">
      <div class="flex flex-col">
        <label>{{ t('profile.suppliers.form.postalCodeLabel') }}</label>
        <InputNumber
          v-model="postalCode"
          :format="false"
          :placeholder="t('profile.suppliers.form.postalCodePlaceholder')"
        />
        <p v-if="errors.postal_code" class="text-sm text-red-600">{{ errors.postal_code[0] }}</p>
      </div>
      <div class="flex flex-col sm:col-span md:col-span-2">
        <label>{{ t('profile.suppliers.form.cityLabel') }}</label>
        <InputText v-model="city" :placeholder="t('profile.suppliers.form.cityPlaceholder')" />
        <p v-if="errors.city" class="text-sm text-red-600">{{ errors.city[0] }}</p>
      </div>
    </div>

    <Button
      :label="t('profile.suppliers.form.editSupplier')"
      type="submit"
      class="mt-4"
      v-if="props.uuid"
    />
    <Button :label="t('profile.suppliers.form.addSupplier')" type="submit" class="mt-4" v-else />
  </form>
</template>
