import GenerationHistoryView from '@/views/Admin/GenerationHistoryView.vue'
import type { RouteRecordRaw } from 'vue-router'

const adminRoutes: RouteRecordRaw[] = [
  {
    path: '/admin',
    name: 'admin',
    redirect: '/admin/generation-history',
    children: [
      {
        path: 'generation-history',
        meta: {
          headerConfig: {
            headerLabel: 'history',
            hide: false,
            showBack: true,
            showProfile: false,
          },
          footerConfig: {
            hide: false,
          },
          securityConfig: {
            requireAuthentication: true,
            rolesAllowed: ['admin'],
          },
          containerMargins: true,
        },
        component: GenerationHistoryView,
      },
    ],
  },
]

export default adminRoutes
