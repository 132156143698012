import MenuDishImageView from '@/views/Menus/MenuDishImageView.vue'
import MenuDishView from '@/views/Menus/MenuDishView.vue'
import MenuFiltersView from '@/views/Menus/MenuFiltersView.vue'
import MenuGenerationResultsView from '@/views/Menus/MenuGenerationResultsView.vue'
import MenuRecipeSteps from '@/views/Menus/MenuRecipeSteps.vue'
import TheIngredientToOrdersView from '@/views/Orders/TheIngredientToOrdersView.vue'
import type { RouteRecordRaw } from 'vue-router'

const menuRoutes: RouteRecordRaw[] = [
  {
    path: '/menu',
    name: 'menu',
    redirect: '/menu/create',
    children: [
      {
        path: 'create',
        meta: {
          headerConfig: {
            headerLabel: 'menu',
            hide: false,
            showBack: false,
            showProfile: true,
          },
          footerConfig: {
            hide: false,
          },
          securityConfig: {
            requireAuthentication: true,
            rolesAllowed: ['common', 'admin'],
          },
          containerMargins: true,
        },
        component: MenuFiltersView,
      },
      {
        path: 'create/ingredient',
        meta: {
          headerConfig: {
            headerLabel: 'menu',
            hide: false,
            showBack: true,
            showProfile: true,
          },
          footerConfig: {
            hide: false,
          },
          securityConfig: {
            requireAuthentication: true,
            rolesAllowed: ['common', 'admin'],
          },
          containerMargins: true,
        },
        component: MenuFiltersView,
        props: { generationType: 'with_main_product' },
      },
      {
        path: 'create/title',
        meta: {
          headerConfig: {
            headerLabel: 'menu',
            hide: false,
            showBack: true,
            showProfile: true,
          },
          footerConfig: {
            hide: false,
          },
          securityConfig: {
            requireAuthentication: true,
            rolesAllowed: ['common', 'admin'],
          },
          containerMargins: true,
        },
        component: MenuFiltersView,
      },
      // {
      //   path: 'create/ingredient',
      //   meta: {
      //     headerConfig: {
      //       headerLabel: 'menu',
      //       hide: false,
      //       showBack: true,
      //       showProfile: true,
      //     },
      //     footerConfig: {
      //       hide: false,
      //     },
      //   },
      //   component: MenuFiltersView,
      //   props: { generationType: 'with_main_product' },
      // },
      // {
      //   path: 'create/title',
      //   meta: {
      //     headerConfig: {
      //       headerLabel: 'menu',
      //       hide: false,
      //       showBack: true,
      //       showProfile: true,
      //     },
      //     footerConfig: {
      //       hide: false,
      //     },
      //   },
      //   component: MenuFiltersView,
      //   props: { generationType: 'from_title' },
      // },
      {
        path: 'results',
        meta: {
          headerConfig: {
            headerLabel: 'menu',
            hide: false,
            showBack: true,
            showProfile: true,
          },
          footerConfig: {
            hide: false,
          },
          securityConfig: {
            requireAuthentication: true,
            rolesAllowed: ['common', 'admin'],
          },
          containerMargins: true,
        },
        component: MenuGenerationResultsView,
      },
      {
        path: 'dishes/:id',
        meta: {
          headerConfig: {
            headerLabel: 'menu',
            hide: false,
            showBack: true,
            showProfile: true,
          },
          footerConfig: {
            hide: false,
          },
          securityConfig: {
            requireAuthentication: true,
            rolesAllowed: ['common', 'admin'],
          },
          containerMargins: true,
        },
        component: MenuDishView,
      },
      {
        path: 'dishes/:id/images',
        meta: {
          headerConfig: {
            headerLabel: 'menu',
            hide: false,
            showBack: true,
            showProfile: false,
          },
          footerConfig: {
            hide: true,
          },
          securityConfig: {
            requireAuthentication: true,
            rolesAllowed: ['common', 'admin'],
          },
          containerMargins: true,
        },
        props: (route) => ({ dishName: route.query.dishName }),
        component: MenuDishImageView,
      },

      {
        path: 'dishes/:id/recipe-steps',
        meta: {
          headerConfig: {
            headerLabel: 'menu',
            hide: false,
            showBack: true,
            showProfile: false,
          },
          footerConfig: {
            hide: true,
          },
          securityConfig: {
            requireAuthentication: true,
            rolesAllowed: ['common', 'admin'],
          },
          containerMargins: true,
        },
        component: MenuRecipeSteps,
      },

      {
        path: 'dishes/:id/add-to-orders',
        meta: {
          headerConfig: {
            headerLabel: 'menu',
            hide: false,
            showBack: true,
            showProfile: true,
          },
          footerConfig: {
            hide: false,
          },
          securityConfig: {
            requireAuthentication: true,
            rolesAllowed: ['common', 'admin'],
          },
          containerMargins: true,
        },
        props: (route) => ({ nbPeople: route.query.nbPeople }),
        component: TheIngredientToOrdersView,
      },
    ],
  },
]

export default menuRoutes
