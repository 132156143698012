<script setup lang="ts">
import type { Supplier } from '@/types/api.js'
import LocationIcon from '@/assets/icons/Suppliers/LocationIcon.vue'
import HeartFull from '@/assets/icons/2States/HeartFull.vue'
import Button from 'primevue/button'
import HeartEmpty from '@/assets/icons/2States/HeartEmpty.vue'
import { useAuth } from '@/composables/useAuth.js'
import { useFetch } from '@/composables/useFetch.js'
import { useEnvStore } from '@/stores/envStore.js'
import Tag from 'primevue/tag'
import { useI18n } from 'vue-i18n'
import type { MessageSchema } from '@/i18n/index.js'

defineProps<{
  supplier: Supplier
  isFavorite: boolean
}>()

const emit = defineEmits<{
  (e: 'updateFavorite', add: boolean): void
}>()

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
const { user } = useAuth()
const { fetchData } = useFetch()

async function toggleFavorite(supplierUuid: string) {
  if (!user.value) return
  const restaurantId = user.value.restaurants[0].id
  fetchData(
    `${useEnvStore().apiUrl}/restaurants/${restaurantId}/suppliers/${supplierUuid}/toggle-favorite`,
    'PATCH'
  ).then((response) => {
    if (response.data) {
      emit('updateFavorite', response.data.status === 'added')
    } else {
      console.log(response.error)
    }
  })
}
</script>

<template>
  <div class="w-full p-3 space-y-3 border-2 shadow-md rounded-xl border-primary-300">
    <div class="flex flex-col gap-1">
      <div class="flex justify-between items-top">
        <div class="flex flex-col gap-1 sm:gap-2 sm:flex-row sm:items-center">
          <span class="text-xl font-bold text-primary-800">{{ supplier.name }}</span>
        </div>
        <Button
          link
          rounded
          :pt="{ root: { class: '!p-1.5 !w-8 !h-8' } }"
          :pt-options="{ mergeProps: true }"
          @click.stop="toggleFavorite(supplier.uuid)"
          v-if="!supplier.is_custom"
        >
          <template #icon>
            <HeartFull v-if="isFavorite" />
            <HeartEmpty v-else />
          </template>
        </Button>
        <div
          v-else
          class="flex items-center justify-center px-1 text-sm border rounded-md w-fit h-fit text-primary-500 border-primary-500"
        >
          {{ t('profile.suppliers.custom') }}
        </div>
      </div>

      <div class="flex gap-2 text-sm text-primary-800" v-if="supplier.tags.length > 0">
        <Tag v-for="tag in supplier.tags" :key="tag.id">
          {{ t('profile.suppliers.tags.' + tag.name) }}
        </Tag>
      </div>
    </div>

    <div
      class="flex justify-between w-full gap-2 max-h-18 sm:max-h-24"
      v-if="supplier.address || supplier.logo_url"
    >
      <div class="flex flex-col justify-between gap-2">
        <div class="flex gap-2 text-sm text-primary-800" v-if="supplier.address">
          <span class="block w-4 aspect-square shrink-0"><LocationIcon /></span>
          <span class="line-clamp-1">
            {{ supplier.address.city.postal_code }} {{ supplier.address.city.name }}
          </span>
        </div>
      </div>
      <div v-if="supplier.logo_url" class="w-1/3 h-20 sm:h-24 shrink-0">
        <img
          :src="supplier.logo_url"
          class="object-contain w-full h-full"
          alt="{{ supplier.name }} logo"
        />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
