<script setup lang="ts">
import GenerationResultCard from '@/components/Menus/GenerationResultCard.vue'
import GenerationInfos from '@/components/Admin/GenerationInfos.vue'
import { useDishesStore } from '@/stores/dishesStore'
import type { Dish } from '@/types/dish'
import { useRouter } from 'vue-router'
import { nextTick, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { MessageSchema } from '@/i18n'
import TitleSuggestionButton from '@/components/Menus/TitleSuggestionButton.vue'
import { useFetch } from '@/composables/useFetch'
import { useEnvStore } from '@/stores/envStore'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from 'primevue/useconfirm'
import { watch } from 'vue'
import ProgressBar from 'primevue/progressbar'
import LoadingBar from '@/components/Skeletons/LoadingBar.vue'
import AddDishTitleToPlanningDialog from '@/components/Planning/AddDishTitleToPlanningDialog.vue'
import type { Ref } from 'vue'
import ConfirmDialog from 'primevue/confirmdialog'
import Button from 'primevue/button'
import { usePlanningStore } from '@/stores/planningStore'

const router = useRouter()

const dishesStore = ref(useDishesStore());
const infoTotal = ref(useDishesStore().infoTotal)
const titlesToShow = ref(5)
const generatingDish = ref(false)
const { fetchData } = useFetch()
const generationParams = ref(useDishesStore().generationParams)
const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
const toast = useToast()
const confirm = useConfirm()
const showAddToPlanningDialog: Ref<boolean> = ref(false)
const selectedDishTitle: Ref<string> = ref('');
const planningStore = usePlanningStore();


// if (storedDishes.length === 0 && dishTitles.length === 0) {
//   router.push({ path: `/menu/create` })
// }

// console.log('dishes store from result : ', storedDishes)

function dishClicked(dish: Dish) {
  console.log(dish)
  useDishesStore().selectedDish = dish
  router.push({ path: `/menu/dishes/${dish.id}` })
}

function showMore() {
  const increment = 5
  if (titlesToShow.value + increment > dishesStore.value.dishTitles.length) {
    titlesToShow.value += dishesStore.value.dishTitles.length - titlesToShow.value // Only add the remainder if increment exceeds the list length
  } else {
    titlesToShow.value += increment // Normal case: add 5
  }
}

function showLess() {
  const decrement = 5
  if (titlesToShow.value - decrement < 5) {
    titlesToShow.value = 5 // Reset to initial count if decrement goes below 5
  } else {
    titlesToShow.value -= decrement // Normal case: subtract 5
  }
}

async function generateDishFromTitle(
  dishTitle: string,
  index: number,
  sourceArray: 'dishTitles' | 'otherDishTitles' | 'otherDishTitlesFromIngredients'
) {
  generatingDish.value = true

  const tempId = Date.now()
  const placeholderDish: Dish = {
    id: tempId,
    name: dishTitle,
    ingredients: [],
    servings: 0,
    difficulty: 1,
    hasRecipe: false,
    loading: true,
    restaurant_id: null,
    author: null,
    created_at: '',
    updated_at: '',
    ai_request_id: 0,
    prep_time_min: 0,
    created_using: 'ia',
    chill_time_min: 0,
    cook_time_min: 0,
    is_favorite: false,
    selected_image: 'gastro'
  }

  dishesStore.value.dishes.push(placeholderDish)

  nextTick(() => {
    const cardElement = document.getElementById(`${tempId}`)
    if (cardElement) {
      const rect = cardElement.getBoundingClientRect()
      window.scrollTo({
        top: window.scrollY + rect.top - 75,
        behavior: 'smooth',
      })
    }
  })

  if (sourceArray === 'dishTitles') {
    dishesStore.value.dishTitles.splice(index, 1)
  } else if (sourceArray === 'otherDishTitles') {
    dishesStore.value.otherDishTitles.splice(index, 1)
  } else {
    dishesStore.value.otherDishTitlesFromIngredients.splice(index, 1)
  }
  console.log('Generation params : ', generationParams.value)
  const { data, error } = await fetchData(
    useEnvStore().apiUrl + '/generate/dish',
    'POST',
    generationParams.value
  )

  if (error || !data.response) {
    const tempDishIndex = dishesStore.value.dishes.findIndex((dish) => dish.id === tempId)
    if (tempDishIndex !== -1) {
      dishesStore.value.dishes.splice(tempDishIndex, 1)
    }
    if (sourceArray === 'dishTitles') {
      dishesStore.value.dishTitles.splice(index, 0, dishTitle)
    } else {
      dishesStore.value.otherDishTitles.splice(index, 0, dishTitle)
    }

    toast.add({
      severity: 'error',
      summary: t('common.error'),
      detail: t('creationFilters.generationError'),
      life: 3000,
    })
    console.log('error', error)
  } else {
    const newDishIndex = dishesStore.value.dishes.findIndex((d) => d.id === placeholderDish.id)
    if (newDishIndex !== -1) {
      dishesStore.value.dishes[newDishIndex] = {
        ...data.response,
      }
    }
    // const date = Date.now()
    console.log('Dish generated : ', data)
    console.log('Dish generated : ', data.response)
  }
  generatingDish.value = false
}

const confirmGenerateNewDish = (event, dishTitle, index, sourceArray) => {
  selectedDishTitle.value = dishTitle;
  confirm.require({
    group: 'templating',
    header: selectedDishTitle.value,
    message: t('menuDishView.generateDishFromTitle.popupLabel', { dishTitle }),
      icon: 'pi pi-question-circle',
      acceptLabel: t('menuDishView.generateDishFromTitle.confirm'),
      rejectLabel: t('menuDishView.generateDishFromTitle.cancel'),
      accept: () => {
        generateDishFromTitle(dishTitle, index, sourceArray)
        toast.add({
          severity: 'info',
          summary: t('common.loading'),
          detail: t('menuDishView.loadingMenuLabel'),
          life: 3000,
        })
      },
      reject: () => {
        console.log('reject')
      },
  });
}

function planDishTitle(rejectCallback) {
  if (!selectedDishTitle.value) return
  planningStore.dishTitleToPlan = {
    clipboardDishTitle: selectedDishTitle.value,
    selectedPlannedMenuIds: [],
    dishType: 'main'
  };

  rejectCallback();
  showAddToPlanningDialog.value = true

}

onMounted(() => {
  if (!dishesStore.value.dishTitles.length  && !dishesStore.value.isGenerating && !dishesStore.value.dishes.length) {
    router.go(-1);
  }
});
</script>

<template>
  <main class="flex flex-col gap-4">
    <LoadingBar :durationInSeconds="18"
      v-if="dishesStore.isGenerating && dishesStore.dishes.length <= 1 && !dishesStore.dishTitles.length" />

    <!-- <ProgressBar :value="50"></ProgressBar> -->
    <GenerationResultCard class="my-7" v-for="dish in dishesStore.dishes" :key="dish.id" :dish
      @click="dish.loading ? null : dishClicked(dish)" :id="dish.id" />


    <div class="mt-5" v-if="dishesStore.dishTitles">
      <h1 v-if="dishesStore.dishes.length">
        {{ dishesStore.dishes.length > 0 ? 'Autres suggestions' : 'Suggestions' }}
      </h1>
      <div class="mt-5" v-if="!dishesStore.dishTitles.length && dishesStore.isGenerating">
        <TitleSuggestionButton v-for="index in 5" :key="index" />
      </div>
      <TitleSuggestionButton :title="dishTitle" @click="confirmGenerateNewDish($event, dishTitle, index, 'dishTitles')"
        v-for="(dishTitle, index) in dishesStore.dishTitles.slice(0, titlesToShow)" :key="index" />
      <p v-if="titlesToShow < dishesStore.dishTitles.length" @click="showMore" class="mb-10 inline-button">
        {{ t('common.showMore') }}
      </p>
    </div>

    <div class="mt-5" v-if="dishesStore.otherDishTitles.length > 0">
      <h1 v-if="dishesStore.dishes">Suggestion spéciales selon pays</h1>
      <TitleSuggestionButton :title="otherDishTitle"
        @click="confirmGenerateNewDish($event, otherDishTitle, index, 'otherDishTitles')"
        v-for="(otherDishTitle, index) in dishesStore.otherDishTitles.slice(0, titlesToShow)" :key="index" />
      <p v-if="titlesToShow < dishesStore.otherDishTitles.length" @click="showMore" class="mb-10 inline-button">
        {{ t('common.showMore') }}
      </p>
    </div>

    <div class="mt-5" v-if="dishesStore.otherDishTitlesFromIngredients.length > 0">
      <h1 v-if="dishesStore.dishes">Suggestion spéciales selon ingrédients</h1>
      <TitleSuggestionButton :title="otherDishTitle" @click="
          confirmGenerateNewDish($event, otherDishTitle, index, 'otherDishTitlesFromIngredients')
        " v-for="(otherDishTitle, index) in dishesStore.otherDishTitlesFromIngredients.slice(0, titlesToShow)"
        :key="index" />
      <p v-if="titlesToShow < dishesStore.otherDishTitlesFromIngredients.length" @click="showMore"
        class="mb-10 inline-button">
        {{ t('common.showMore') }}
      </p>
    </div>

    <ConfirmDialog group="templating">
      <template #container="{ message, acceptCallback, rejectCallback }">
        <div class="flex flex-col p-5 rounded bg-white">
          <button @click="rejectCallback" class="absolute top-1 right-1 p-2 text-gray-600 hover:text-gray-900">
            <i class="pi pi-times"></i>
          </button>
          <span class="font-bold text-xl block mb-2 mt-4">{{ message.header }}</span>
          <p class="mb-4">{{ message.message }}</p>
          <div class="flex justify-between gap-2">
            <Button :label="message.rejectLabel" outlined @click="rejectCallback"></Button>
            <div class="flex justify-end gap-2">
              <Button :label="t('menuDishView.generateDishFromTitle.planDishTitle')"
                @click="planDishTitle(rejectCallback)" severity="secondary"></Button>
              <Button :label="message.acceptLabel" @click="acceptCallback"></Button>
            </div>
          </div>
        </div>
      </template>
    </ConfirmDialog>

    <GenerationInfos :info-total="dishesStore.infoTotal"
      v-if="dishesStore.infoTotal && useEnvStore().environment !== 'production'" />
    <AddDishTitleToPlanningDialog v-model:visible="showAddToPlanningDialog"
      @close-sidebar="showAddToPlanningDialog = false" />
  </main>
</template>

<style>
.button {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
}

.inline-button {
  color: #ed8d4c;
  font-weight: 500;
}

.inline-button:hover {
  cursor: pointer;
}

.button:hover {
  background-color: #3e8e41;
  cursor: pointer;
}
</style>
