<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import {
  useDishCreationEditionFormStore,
  useDishesStore,
  useRecipeStore,
} from '@/stores/dishesStore'
import { useEnvStore } from '@/stores/envStore'
import { useFetch } from '@/composables/useFetch'
import Textarea from 'primevue/textarea'
import Button from 'primevue/button'
import Slider from 'primevue/slider'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { onMounted, ref, watchEffect } from 'vue'
import InputText from 'primevue/inputtext'
import InputNumber from '@/components/BaseComponents/InputNumber.vue'
import { useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast'
import type { Ref } from 'vue'
import type { HistoryState } from '@/types/history.js'
import EditIngredient from '@/components/Orders/EditIngredient.vue'
import type { Ingredient } from '@/types/dish'

const props = defineProps<{
  dishEdition: boolean
}>()

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const router = useRouter()
const toast = useToast()
const recipe = useDishCreationEditionFormStore().creationEditionDish
const { fetchData, isFetching } = useFetch()
const ingredientEdition = ref(true)
const showSidebar = ref(false)

const sideBarIngredient: Ref<Ingredient> = ref({
  id: -1,
  category: 'other',
  name: '',
  quantity: 0,
  unit: '',
  price: 0,
})
const sideBarIngredientIndex = ref(0)

/**
 * Save the dish in the database
 */
async function saveDish() {
  if (!recipe) return

  console.log(recipe.dish_data.ingredients)

  // Prevent ingredient with no categories
  recipe.dish_data.ingredients.forEach((ingredient) => {
    if (ingredient.category === '') ingredient.category = 'other'
  })

  // Remove the possible empty steps
  recipe.dish_data.steps.forEach((step) => {
    step.instructions = step.instructions.filter((instruction) => instruction !== '')
  })

  // Prepare fetch
  const url = props.dishEdition ? `/dishes/${recipe.dish_data.id}` : '/dishes/import/save'
  const method = props.dishEdition ? 'PUT' : 'POST'

  // Save in the database
  console.log('Saving dish...')

  fetchData(`${useEnvStore().apiUrl}${url}`, method, recipe).then(async ({ data, error }) => {
    if (error) {
      console.error('Upload failed:', error)
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: props.dishEdition
          ? t('profileFavorites.editError')
          : t('profileFavorites.addError'),
        life: 3000,
      })
    } else {
      console.log('Upload successful:')
      console.log(data.name)
      toast.add({
        severity: 'success',
        summary: t('common.success'),
        detail: props.dishEdition
          ? t('profileFavorites.editSucces')
          : t('profileFavorites.addSucces'),
        life: 3000,
      })

      const { data: recipe, error: recipeError } = await fetchData(
        useEnvStore().apiUrl + `/dishes/${data.id}/recipe`
      )
      if (recipeError) {
        console.error(error)
      } else {
        console.log(recipe)
        useRecipeStore().recipe = recipe
      }

      useDishesStore().selectedDish = data

      const savedState = sessionStorage.getItem('historyState')

      if (savedState) {
        let state: HistoryState = JSON.parse(savedState)
        const editedDishIndex = state.dishes.findIndex((dish) => dish.id === data.id)
        state.dishes[editedDishIndex] = data
        sessionStorage.setItem('historyState', JSON.stringify(state))
      }

      router.back()
    }
  })
}

// Add new ingredient to the list
function addIngredient() {
  const tempId = Date.now() + Math.floor(Math.random() * 1000)
  sideBarIngredient.value.id = tempId
  recipe?.dish_data.ingredients.push(sideBarIngredient.value)
  showSidebar.value = false
}

// Edit existing ingredient in the list
function editIngredient() {
  if (!recipe) return
  if (sideBarIngredientIndex.value !== -1) {
    if (sideBarIngredient.value.price === null) sideBarIngredient.value.price = 0
    recipe.dish_data.ingredients[sideBarIngredientIndex.value] = { ...sideBarIngredient.value }
  }
  showSidebar.value = false
}
function deleteIngredient() {
  if (!recipe) return
  if (sideBarIngredientIndex.value !== -1) {
    recipe.dish_data.ingredients.splice(sideBarIngredientIndex.value, 1)
  }
  showSidebar.value = false
}

// Triggered when an ingredient is clicked.
function prepareEdit(ingredient: Ingredient) {
  if (!recipe) return
  sideBarIngredientIndex.value = recipe?.dish_data.ingredients.findIndex(
    (ing) => ing.id === ingredient.id
  )
  sideBarIngredient.value = { ...ingredient }
  ingredientEdition.value = true
  showSidebar.value = true
}

onMounted(() => {
  if (props.dishEdition && !recipe?.dish_data.id) router.push({ name: 'profile' })
})

watchEffect(() => {
  if (recipe?.dish_data.chill_time_min === null) recipe.dish_data.chill_time_min = 0
  if (recipe?.dish_data.prep_time_min === null) recipe.dish_data.prep_time_min = 0
  if (recipe?.dish_data.cook_time_min === null) recipe.dish_data.cook_time_min = 0
})
</script>

<template>
  <main>
    <div v-if="recipe" class="flex flex-col gap-8">
      <div>
        <h2>{{ t('profileFavorites.creationForm.informations') }}</h2>
        <div class="flex flex-col gap-2">
          <div class="flex flex-col">
            <label>{{ t('profileFavorites.creationForm.recipeName') }}</label>
            <InputText
              v-model="recipe.dish_data.name"
              :placeholder="t('profileFavorites.creationForm.dishNamePlaceholder')"
              :disabled="isFetching"
            />
          </div>
          <div class="flex flex-col">
            <label>{{ t('profileFavorites.creationForm.numberOfPeople') }}</label>
            <InputNumber
              v-model="recipe.dish_data.servings"
              :placeholder="t('profileFavorites.creationForm.servingsPlaceholder')"
              :disabled="isFetching"
            />
          </div>
          <div>
            <label>{{ t('profileFavorites.creationForm.difficulty') }}</label>
            <Slider
              :min="1"
              :max="4"
              :step="1"
              v-model:model-value="recipe.dish_data.difficulty"
              :disabled="isFetching"
            />
          </div>
          <div class="flex flex-col">
            <label>{{ t('profileFavorites.creationForm.prepTime') }} (min)</label>
            <InputNumber
              v-model="recipe.dish_data.prep_time_min"
              :placeholder="t('profileFavorites.creationForm.timePlaceholder')"
              :disabled="isFetching"
              :min="0"
            />
          </div>
          <div class="flex flex-col">
            <label>{{ t('profileFavorites.creationForm.chillTime') }} (min)</label>
            <InputNumber
              v-model="recipe.dish_data.chill_time_min"
              :placeholder="t('profileFavorites.creationForm.timePlaceholder')"
              :disabled="isFetching"
              :min="0"
            />
          </div>
          <div class="flex flex-col">
            <label>{{ t('profileFavorites.creationForm.cookTime') }} (min)</label>
            <InputNumber
              v-model="recipe.dish_data.cook_time_min"
              :placeholder="t('profileFavorites.creationForm.timePlaceholder')"
              :disabled="isFetching"
              :min="0"
            />
          </div>
        </div>
      </div>

      <div>
        <h2>{{ t('profileFavorites.creationForm.ingredients') }}</h2>
        <DataTable
          :value="recipe.dish_data.ingredients"
          size="small"
          @row-click="(evt) => prepareEdit(evt.data)"
        >
          <Column field="quantity" :header="t('profileFavorites.creationForm.quantityShort')" />
          <Column field="unit" :header="t('profileFavorites.creationForm.unit')" />
          <Column field="name" :header="t('profileFavorites.creationForm.name')" />
          <Column field="price" :header="t('profileFavorites.creationForm.price')" />
        </DataTable>

        <!-- <Button :label="t('profileFavorites.creationForm.addIngredientButton')" @click="() => {
          showSidebar = true
          ingredientEdition = false
        }
          " /> -->
        <div
          class="hover:cursor-pointer"
          @click="
            () => {
              showSidebar = true
              ingredientEdition = false
            }
          "
        >
          <p>+ {{ t('profileFavorites.creationForm.addIngredientButton') }}</p>
        </div>
      </div>

      <div class="mb-20">
        <h2>{{ t('profileFavorites.creationForm.steps') }}</h2>
        <div v-for="(step, index) in recipe.dish_data.steps" :key="index" class="mb-4">
          <h3>{{ t('profileFavorites.creationForm.step') }} {{ index + 1 }}</h3>
          <div class="grid mb-2 md:gap-2 md:grid-cols-6">
            <b class="col-span-1">{{ t('profileFavorites.creationForm.stepTitle') }}</b>
            <div class="col-span-5">
              <div class="flex flex-col">
                <InputText v-model="step.name" placeholder="Ex: Carottes" :disabled="isFetching" />
              </div>
            </div>
          </div>
          <div
            v-for="(instruction, index) in step.instructions"
            :key="index"
            class="grid mb-2 md:gap-2 md:grid-cols-6"
          >
            <b class="col-span-1">
              {{ index === 0 ? t('profileFavorites.creationForm.instructions') : '' }}
            </b>
            <div class="col-span-5">
              <Textarea
                v-model="step.instructions[index]"
                class="w-full"
                :auto-resize="true"
                :disabled="isFetching"
              />
            </div>
          </div>
          <div class="flex justify-end">
            <Button
              :label="t('profileFavorites.creationForm.addInstructionButton')"
              @click="step.instructions.push('')"
              :disabled="isFetching"
            />
          </div>
        </div>
      </div>
      <div class="fixed inset-x-0 bottom-0 z-50 px-4 py-3 bg-white custom-shadow">
        <Button
          :label="t('profileFavorites.creationForm.saveButton')"
          @click="saveDish"
          :loading="isFetching"
          class="w-full"
        />
      </div>
    </div>
    <EditIngredient
      v-model:ingredient="sideBarIngredient"
      :index="sideBarIngredientIndex"
      v-model:visible="showSidebar"
      @add-ingredient="addIngredient"
      @edit-ingredient="editIngredient()"
      @delete-ingredient="deleteIngredient"
      :showPrice="true"
    />

    <!-- <Sidebar v-model:visible="showSidebar"
      :header="`${ingredientEdition ? t('profileFavorites.creationForm.edit') : t('profileFavorites.creationForm.add')} un ingrédient`"
      position="bottom" style="height: auto" :block-scroll="true" @hide="resetSideBarIngredient">
      <div class="flex flex-col gap-4">
        <div class="flex flex-col">
          <label>{{ t('profileFavorites.creationForm.name') }}</label>
          <InputText v-model="sideBarIngredient.name"
            :placeholder="t('profileFavorites.creationForm.namePlaceholder')" />
        </div>
        <div class="grid grid-cols-2 gap-4">
          <div class="flex flex-col">
            <label>{{ t('profileFavorites.creationForm.quantityLong') }}</label>
            <InputNumber v-model="sideBarIngredient.quantity"
              :placeholder="t('profileFavorites.creationForm.quantityPlaceholder')" :min-fraction-digits="2" />
          </div>
          <div class="flex flex-col">
            <label>{{ t('profileFavorites.creationForm.unit') }}</label>
            <InputText v-model="sideBarIngredient.unit"
              :placeholder="t('profileFavorites.creationForm.unitPlaceholder')" />
          </div>
          <div class="flex flex-col">
            <label>{{ t('profileFavorites.creationForm.price') }}</label>
            <InputNumber v-model="sideBarIngredient.price"
              :placeholder="t('profileFavorites.creationForm.pricePlaceholder')" :min-fraction-digits="2" />
          </div>
        </div>
        <Button v-if="ingredientEdition" :label="t('profileFavorites.creationForm.deleteIngredientButton')"
          @click="deleteIngredient" severity="danger" text />
        <Button :label="
            ingredientEdition
              ? t('profileFavorites.creationForm.edit')
              : t('profileFavorites.creationForm.add')
          " @click="ingredientEdition ? editIngredient() : addIngredient()" :disabled="
            sideBarIngredient.name === '' ||
            (!sideBarIngredient.quantity && sideBarIngredient.unit !== '')
          " />
      </div>
    </Sidebar> -->
  </main>
</template>

<style scoped>
.custom-shadow {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  /* Increase the opacity and spread for visibility */
}
</style>
