<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import type { Dish, Ingredient } from '@/types/dish.js'
import InputNumber from '@/components/BaseComponents/InputNumber.vue'
import Button from 'primevue/button'
import ButtonOrders from '@/assets/icons/Buttons/ButtonOrders.vue'
import NavPlanningIcon from '@/assets/icons/NavBar/NavPlanningIcon.vue'
import ButtonWithIconWrapper from '@/components/BaseComponents/ButtonWithIconWrapper.vue'
import ButtonImage from '@/assets/icons/Buttons/ButtonImage.vue'
import ButtonMenu from '@/assets/icons/Buttons/ButtonMenu.vue'
import ButtonList from '@/assets/icons/Buttons/ButtonList.vue'
import DishImage from '@/components/Menus/DishImage.vue'
import { calculateDishPrice } from '@/utils/prices'
import { computed, onMounted, ref, watch } from 'vue'
import { useDishesStore, useRecipeStore } from '@/stores/dishesStore'
import { useFetch } from '@/composables/useFetch'
import Menu from 'primevue/menu'
import { useRoute } from 'vue-router'
import { useEnvStore } from '@/stores/envStore'
import { useRouterStore } from '@/stores/routerStore'
import { useLayoutStore } from '@/stores/layoutStore'
import { useToast } from 'primevue/usetoast'
import router from '@/router'
import LoadingView from '../LoadingView.vue'
import { useAuth } from '@/composables/useAuth'
import { debounce } from '@/utils/functions'
import { useDishes } from '@/composables/useDishes.js'
import type { HistoryState } from '@/types/history.js'
import type { Ref } from 'vue'
import EditIngredient from '@/components/Orders/EditIngredient.vue'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { transformAndSortIngredients } from '@/utils/dishes'
import ButtonConfirm from '@/assets/icons/Buttons/ButtonConfirm.vue'
import ButtonClose from '@/assets/icons/Buttons/ButtonClose.vue'
import Textarea from 'primevue/textarea'
import Dialog from 'primevue/dialog'
import LoadingBar from '@/components/Skeletons/LoadingBar.vue'
import { useConfirm } from 'primevue/useconfirm'
import { usePlanningStore } from '@/stores/planningStore'
import AddDishToPlanningDialog from '@/components/Planning/AddDishToPlanningDialog.vue'
import HeartFull from '@/assets/icons/2States/HeartFull.vue'
import HeartEmpty from '@/assets/icons/2States/HeartEmpty.vue'

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
const dishesStore = useDishesStore()
const recipeStore = useRecipeStore()
const planningStore = usePlanningStore()
const { fetchData } = useFetch()
const { deleteDish, duplicateDish, toggleFavorite } = useDishes()
const route = useRoute()
const toast = useToast()
const user = ref(useAuth().user)
const dishImageRef: Ref<InstanceType<typeof DishImage> | null> = ref(null)
const isImageModalVisible = ref(false)
const fileInput: Ref<HTMLInputElement | null> = ref(null)
const dish = ref<Dish | null>(null)
const newDishName = ref('')
const isEditingName = ref(false)
const numberOfPeople = ref()
const dishId = ref(parseInt(route.params.id.toString()))

const showDishSkeleton = computed(() => {
  if (!dish.value) {
    return true
  }
  if (dish.value?.only_title) {
    return true
  }
  return false
})
// const aiModel = ref('gpt-4o')
// const temperature = ref(0.8)
const generatingRecipe = ref(false)
const generatingImage = ref(false)
const ingredientToEdit: Ref<Ingredient> = ref({
  id: -1,
  category: 'other',
  name: '',
  quantity: 0,
  unit: '',
  price: 0,
})
const ingredientToEditIndex = ref(-1)
const showSidebar: Ref<boolean> = ref(false)
const confirm = useConfirm()
const showAddToPlanningDialog: Ref<boolean> = ref(false)

const menu = ref()
const favoriteLabel = computed(() =>
  dish.value?.is_favorite
    ? t('dishOperations.removeFromFavortite')
    : t('dishOperations.addToFavorites')
)
const favoriteIcon = computed(() => (dish.value?.is_favorite ? 'pi pi-heart-fill' : 'pi pi-heart'))
const items = ref([
  {
    items: [
      {
        label: t('menuDishView.topMenu.addtoWeekMenu'),
        icon: 'pi pi-calendar',
        command: () =>
          addToWeekMenu()
      },
      // {
      //   label: favoriteLabel,
      //   icon: favoriteIcon,
      //   command: () =>
      //     toggleFavorite(dishId.value).then((favoriteResponse) =>
      //       handleFavoriteChange(favoriteResponse)
      //     ),
      // },
      {
        label: t('menuDishView.topMenu.rename'),
        icon: 'pi pi-pencil',
        command: () => {
          newDishName.value = dish.value?.name ?? ''
          isEditingName.value = true
        },
      },
      {
        label: t('menuDishView.topMenu.duplicate'),
        icon: 'pi pi-clone',
        command: () =>
          duplicateDish(dishId.value, 'copy').then((duplicationResponse) =>
            handleDuplication(duplicationResponse)
          ),
      },
      {
        label: t('menuDishView.topMenu.delete'),
        icon: 'pi pi-trash',
        command: () => deleteDish(dishId.value).then((wasDeleted) => handleDeletion(wasDeleted)),
      },
      {
        label: 'Ajouter une photo perso.',
        icon: 'pi pi-camera',
        command: () => fileInput.value?.click(),
      },
      // {
      //   label: t('menuDishView.topMenu.share'),
      //   icon: 'pi pi-share-alt',
      // },
    ],
  },
])

async function addToWeekMenu() {
  if (!dish.value) return
  planningStore.dishToPlan = {
    clipboardDish: dish.value,
    selectedPlannedMenuIds: [],
    dishType: 'main'
  };

  showAddToPlanningDialog.value = true

  // // Prepare the data to send in the request
  // const payload = {
  //   dish_id: dish.value.id,
  //   date: new Date().toISOString().split('T')[0], // current date in YYYY-MM-DD format
  //   restaurant_id: user.value?.restaurants[0]?.id || null // assuming the user has at least one restaurant
  // }

  // if (!payload.restaurant_id) {
  //   toast.add({
  //     severity: 'error',
  //     summary: t('common.error'),
  //     detail: t('auth.user.noRestaurantConnected'),
  //     life: 3000
  //   })
  //   return
  // }

  // // Make the POST request to plan the dish
  // const { error, data } = await fetchData(`${useEnvStore().apiUrl}/planning/plan`, 'POST', payload)

  // if (error) {
  //   toast.add({
  //     severity: 'error',
  //     summary: t('common.error'),
  //     detail: t('menuDishView.addToWeekMenuError'),
  //     life: 3000
  //   })
  // } else {
  //   toast.add({
  //     severity: 'success',
  //     summary: t('common.success'),
  //     detail: t('menuDishView.addedToWeekMenu'),
  //     life: 3000
  //   })
  // }
}

function handleDeletion(wasDeleted: boolean) {
  if (wasDeleted) {
    toast.add({
      severity: 'success',
      summary: t('common.success'),
      detail: t('dishOperations.dishDeletionSuccess'),
      life: 3000,
    })

    const stateNames = ['historyState', 'myScansState', 'favoriteState']
    stateNames.forEach((stateName) => {
      const savedState = sessionStorage.getItem(stateName)
      if (savedState) {
        let state: HistoryState = JSON.parse(savedState)
        state.dishes = state.dishes.filter((stateDish) => stateDish.id !== dishId.value)
        sessionStorage.setItem(stateName, JSON.stringify(state))
      }
    })

    dishesStore.dishes = dishesStore.dishes.filter((storedDish) => {
      storedDish.id === dishId.value
    })

    if (
      useRouterStore().previousPath === '/profile/my-scans' ||
      useRouterStore().previousPath === '/profile/favorites' ||
      useRouterStore().previousPath === '/profile/generation-history' ||
      useRouterStore().previousPath === '/menu/results'
    ) {
      router.back()
    } else {
      router.push({ path: '/' })
    }
  } else {
    toast.add({
      severity: 'error',
      summary: t('common.error'),
      detail: t('dishOperations.dishDeletionError'),
      life: 3000,
    })
  }
}

function handleDuplication(duplicationResponse: null | Dish) {
  if (duplicationResponse === null) {
    toast.add({
      severity: 'error',
      summary: t('common.error'),
      detail: t('dishOperations.dishDuplicationError'),
      life: 3000,
    })
  } else {
    const originalDishId = dishId.value
    dish.value = duplicationResponse
    dishId.value = duplicationResponse.id
    dishesStore.selectedDish = duplicationResponse
    recipeStore.recipe = null

    const stateNames = ['historyState', 'myScansState', 'favoriteState']
    stateNames.forEach((stateName) => {
      const savedState = sessionStorage.getItem(stateName)
      if (savedState) {
        let state: HistoryState = JSON.parse(savedState)
        console.log(state)
        const stateDishIndex = state.dishes.findIndex(
          (stateDish) => stateDish.id === originalDishId
        )
        state.dishes.splice(stateDishIndex, 0, duplicationResponse)
        sessionStorage.setItem(stateName, JSON.stringify(state))
      }
    })

    router.replace('/menu/dishes/' + duplicationResponse.id)

    toast.add({
      severity: 'success',
      summary: t('common.success'),
      detail: t('dishOperations.dishDuplicationSuccess'),
      life: 3000,
    })
  }
}

function handleFavoriteChange(favoriteResponse: boolean | null) {
  if (favoriteResponse === null) {
    toast.add({
      severity: 'error',
      summary: t('common.error'),
      detail: t('dishOperations.dishFavoriteError'),
      life: 3000,
    })
  } else {
    dish.value!.is_favorite = favoriteResponse

    const stateNames = ['historyState', 'myScansState', 'favoriteState']
    stateNames.forEach((stateName) => {
      const savedState = sessionStorage.getItem(stateName)
      if (savedState) {
        let state: HistoryState = JSON.parse(savedState)
        console.log(state)
        state.dishes.find((dish) => dish.id === dishId.value)!.is_favorite = favoriteResponse
        sessionStorage.setItem(stateName, JSON.stringify(state))
      }
    })

    toast.add({
      severity: 'success',
      summary: t('common.success'),
      detail: favoriteResponse
        ? t('dishOperations.dishFavoriteAdded')
        : t('dishOperations.dishFavoriteRemoved'),
      life: 3000,
    })
  }
}

const toggle = (event: any) => {
  menu.value.toggle(event)
}

onMounted(async () => {
  console.log(route.params.id)
  if (!user.value) return;
  
  if (!dishesStore.selectedDish || dishesStore.selectedDish.id.toString() !== route.params.id) {
    const { data, error } = await fetchData(`${useEnvStore().apiUrl}/dishes/${dishId.value}`)
    if (error) {
      console.error(error)
      router.back()
    } else {
      dish.value = data as Dish
      if (dish.value.only_title) { // TODO REMOVE
        // // generatingRecipe.value = true;
        // // if (!user.value.restaurants[0]) router.go(-1);
        // const restaurantId: string = user.value.restaurants[0].id
        // const theDishToGenerate = {...dish.value} as Dish;
        // // dish.value = null
        // dish.value = await dishesStore.generateDishFromTitle(theDishToGenerate.name, restaurantId, theDishToGenerate.id)
        // // generatingRecipe.value = false;
        // if (!dish.value) {
        //   toast.add({
        //     severity: 'error',
        //     summary: t('common.error'),
        //     detail: t('creationFilters.generationError'),
        //     life: 3000,
        //   })
        //   router.go(-1);
        //   return;
        // }
        // toast.add({
        //   severity: 'success',
        //   summary: t('common.success'),
        //   detail: t('creationFilters.generationSuccess'),
        //   life: 3000,
        // })
      }
      dishesStore.selectedDish = dish.value
      console.log(data)
      numberOfPeople.value = dish.value.servings
    }
  } else {
    dish.value = dishesStore.selectedDish
    numberOfPeople.value = dish.value.servings
  }
})

async function getRecipe(hasRecipe: boolean, event) {
  console.log('hasRecipe', hasRecipe)

  if (!hasRecipe) {
    createRecipe()
  } else if (dish.value?.needs_recipe_update) {
    confirmSeeOrGenerateNewRecipe(event)
  } else {
    showRecipe(dishId.value)
  }
}

const confirmSeeOrGenerateNewRecipe = (event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t('menuDishView.recipe.seeOrUpdateButton.label'),
    icon: 'pi pi-exclamation-triangle',
    acceptLabel: t('menuDishView.recipe.seeOrUpdateButton.regenerate'),
    rejectLabel: t('menuDishView.recipe.seeOrUpdateButton.see'),
    accept: () => {
      if (!user.value || user.value.restaurants.length === 0) {
        toast.add({
          severity: 'error',
          summary: t('common.error'),
          detail: t('auth.user.noRestaurantConnected'),
          life: 3000,
        })
        return
      }
      createRecipe()
      // removeDishIngredients(dishId.value, restaurantId)
      // removeFromShoppingList()
    },
    reject: () => {
      showRecipe(dishId.value)
    },
  })
}

async function getImage(hasIaImage) {
  console.log('image ')
  if (!hasIaImage) {
    if (!dish.value || !user.value || !dishesStore.selectedDish) {
      console.log('Required data not available.')
      return
    }
    let theDishId = Number(dishId.value)
    dishesStore.generateImages(theDishId, toast)
    router.push({ path: `/menu/dishes/${theDishId}/images` })
  } else {
    router.push({ path: `/menu/dishes/${dishId.value}/images` })
  }
}

async function showRecipe(dishId: number) {
  console.log('dishId ' + dishId)
  console.log('recipestore ' + recipeStore.recipe)
  if (recipeStore.recipe?.dish_id !== dishId || !recipeStore.recipe) {
    generatingRecipe.value = true
    useLayoutStore().pageMeta.headerConfig.showBack = false
    useLayoutStore().pageMeta.headerConfig.showProfile = false
    const { data, error } = await fetchData(
      useEnvStore().apiUrl + `/dishes/${dishId}/recipe`,
      'GET'
    )
    if (error) {
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: t('menuRecipeSteps.gettingRecipeError'),
        life: 3000,
      })
      console.log('error', error)
    } else {
      recipeStore.recipe = data
      console.log('data', data)
      router.push({ path: `/menu/dishes/${dishId}/recipe-steps` })
      generatingRecipe.value = false
    }
  } else {
    router.push({ path: `/menu/dishes/${dishId}/recipe-steps` })
  }
}

async function createRecipe(aiModel = 'gpt-4o', temperature = 0.6) {
  generatingRecipe.value = true
  console.log('Params : ', aiModel, temperature)
  useLayoutStore().pageMeta.headerConfig.showBack = false
  useLayoutStore().pageMeta.headerConfig.showProfile = false
  const { data, error } = await fetchData(
    useEnvStore().apiUrl +
      `/generate/recipe/${dishId.value}?aiModel=${aiModel}&temperature=${temperature}`,
    'GET'
  )

  if (error) {
    toast.add({
      severity: 'error',
      summary: t('common.error'),
      detail: t('creationFilters.generationError'),
      life: 3000,
    })
    console.log('error', error)
  } else {
    const date = Date.now()
    recipeStore.recipe = data.response
    if (dish.value) {
      dish.value.hasRecipe = true
      dish.value.needs_recipe_update = false
      dish.value.chill_time_min = recipeStore.recipe?.chill_time || 0
      dish.value.cook_time_min = recipeStore.recipe?.cook_time || 0
      dish.value.prep_time_min = recipeStore.recipe?.prep_time || 0
    }
    recipeStore.infoTotal = {
      date: date,
      temperature: data.temperature,
      ...data.response,
      usage: data.usage,
      status: data.status,
      ai_model: data.ai_model,
      duration: data.duration,
      instructions: data.instructions,
      thread_id: data.thread_id,
    }
    console.log('API URL  : ', `/menu/dishes/${dishId.value}/recipe-steps`)
    // console.log('My store : ', useDishesStore().dishes)

    toast.add({
      severity: 'success',
      summary: t('common.success'),
      detail: t('creationFilters.generationSuccess'),
      life: 3000,
    })
    console.log('data', data)
    router.push({ path: `/menu/dishes/${dishId.value}/recipe-steps` })
  }
  generatingRecipe.value = false
}

async function updateServings(servings: number) {
  try {
    const theDishId = dishId.value
    const { error } = await fetchData(
      useEnvStore().apiUrl + `/dishes/${theDishId}/servings`,
      'PUT',
      { servings: servings }
    )

    if (error) {
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: 'Error', // TODO : update
        life: 3000,
      })
      console.log('error', error)
    } else {
      console.log('Servings updated successfully')
      if (dish.value) dish.value.servings = servings
    }
  } catch (error) {
    console.error('Error updating servings:', error)
  }
}

const debouncedUpdateServings = debounce((value: number) => {
  updateServings(value)
}, 400)

const hasImage = computed(() => {
  return dish.value?.image_url_gastro || dish.value?.image_url_bistro || dish.value?.image_url_user
})
const hasIaImage = computed(() => {
  return dish.value?.image_url_gastro || dish.value?.image_url_bistro
})

async function uploadUserImage(event: any) {
  const files = event.target.files
  if (files.length > 0) {
    const file: File = files[0]
    console.log(file)

    const formData = new FormData()
    formData.append('imageUser', file)

    fetchData(`${useEnvStore().apiUrl}/dishes/${dishId.value}/images`, 'POST', formData).then(
      ({ data }) => {
        if (data && dish.value) {
          dish.value.image_url_user = data.updated_values.image_url_user
          if (dish.value.selected_image === 'user' && dishImageRef.value) {
            dishImageRef.value.getImage()
          } else {
            dish.value.selected_image = 'user'
          }
          toast.add({
            severity: 'success',
            summary: t('common.success'),
            detail: t('menuDishView.addUserImageSuccess'),
            life: 3000,
          })
        } else {
          toast.add({
            severity: 'error',
            summary: t('common.error'),
            detail: t('menuDishView.addUserImageError'),
            life: 3000,
          })
        }
      }
    )
  }
}

watch(numberOfPeople, (newVal) => {
  if (newVal && newVal !== 0) debouncedUpdateServings(newVal)
})

function addOrUpdateIngredient(ingredient: Ingredient | null) {
  if (ingredient) {
    const adjustedIngredient = listIngredients.value.find((ing) => ing.id === ingredient.id)
    ingredientToEdit.value = adjustedIngredient ? { ...adjustedIngredient } : { ...ingredient }
  } else {
    ingredientToEdit.value = { id: -1, category: '', name: '', quantity: 0, unit: '', price: 0 }
    ingredientToEditIndex.value = -1
  }
  showSidebar.value = true
}

watch(
  () => dishesStore.selectedDish,
  (newSelectedDish) => {
    dish.value = newSelectedDish
  },
  { deep: true }
)

function addIngredient() {
  if (ingredientToEdit.value.name) {
    dishesStore.addIngredients(dishId.value, [ingredientToEdit.value])
    showSidebar.value = false // Close the sidebar after adding
  } else {
    console.error('Ingredient data is incomplete.')
  }
}

function updateIngredient() {
  if (ingredientToEdit.value.id !== -1 && ingredientToEdit.value.name) {
    dishesStore.updateIngredients(dishId.value, [ingredientToEdit.value])
    showSidebar.value = false // Close the sidebar after editing
    console.log('NEW DISH', dish.value)
  } else {
    console.error('Ingredient data is incomplete or invalid ID.')
  }
}

function deleteIngredients() {
  const ingredientId = ingredientToEdit.value.id
  if (ingredientId !== -1) {
    dishesStore.deleteIngredients(dishId.value, [ingredientId])
    showSidebar.value = false // Close the sidebar after deleting
  } else {
    console.error('Invalid ingredient ID for deletion.')
  }
}

const listIngredients = computed(() => {
  if (!dish.value) return []
  return transformAndSortIngredients(dish.value.ingredients, numberOfPeople.value)
})

async function editName() {
  if (!dish.value) return
  const { error } = await fetchData(useEnvStore().apiUrl + '/dishes/' + dishId.value, 'PATCH', {
    name: newDishName.value,
  })

  if (error) {
    toast.add({
      severity: 'error',
      summary: t('common.error'),
      detail: t('menuDishView.updateNameError'),
      life: 3000,
    })
  } else {
    dish.value.name = newDishName.value
    if (dishesStore.selectedDish !== null) {
      dishesStore.selectedDish.name = newDishName.value
    }
    const index = dishesStore.dishes.findIndex((dish) => dish.id === dishId.value)
    if (index !== -1) dishesStore.dishes[index].name = newDishName.value

    const stateNames = ['historyState', 'myScansState', 'favoriteState']
    stateNames.forEach((stateName) => {
      const savedState = sessionStorage.getItem(stateName)
      if (savedState) {
        let state: HistoryState = JSON.parse(savedState)
        state.dishes.find((dish) => dish.id === dishId.value)!.name = dish.value!.name
        sessionStorage.setItem(stateName, JSON.stringify(state))
      }
    })
    toast.add({
      severity: 'success',
      summary: t('common.success'),
      detail: t('menuDishView.updateNameSucces'),
      life: 3000,
    })
    isEditingName.value = false
  }
}

const showAllDates = ref(false)

const displayedPlannedDishes = computed(() => {
  if (showAllDates.value) {
    return dish.value?.isPlanned || []
  } else {
    return dish.value?.isPlanned?.slice(0, 3) || []
  }
})

const formatDate = (dateString) => {
  return new Date(dateString).toLocaleString('fr-CH', { dateStyle: 'short' })
}
</script>

<template>
  <main class="relative flex flex-col gap-6">
    <Dialog
      v-if="dish"
      v-model:visible="isImageModalVisible"
      modal
      style="max-width: 80%"
      class="sm:max-w-3xl"
    >
      <DishImage
        :dishId="dish.id"
        :type="dish?.selected_image ?? undefined"
        :alt="dish.name"
        size="full"
      />
    </Dialog>
    <div v-show="!generatingRecipe && !generatingImage">
      <div class="my-4">
        <div v-if="!isEditingName" class="flex justify-between gap-6 items-top">
          <h3 v-if="dish">
            {{ dish?.only_title ? t('menuDishView.generatingDish') : dish.name }}
          </h3>
          <h3 v-else>
            {{ t('common.loading') }}
          </h3>
          <div class="flex flex-row gap-2">
            <Button v-if="dish" link rounded :pt="{ root: { class: '!p-1.5 !w-8 !h-8' } }"
              :pt-options="{ mergeProps: true }" @click="
                toggleFavorite(dishId).then((favoriteResponse) =>
                  handleFavoriteChange(favoriteResponse)
                )">
              <template #icon>
                <HeartFull v-if="dish.is_favorite" />
                <HeartEmpty v-else />
              </template>
            </Button>
            <Button link rounded :pt="{ root: { class: '!p-1.5 !w-8 !h-8' } }" :pt-options="{ mergeProps: true }"
              @click="toggle" :disabled="!dish">
              <template #icon>
                <ButtonMenu />
              </template>
            </Button>
          </div>
        </div>
        <div v-else class="flex justify-between gap-2 items-top">
          <Textarea class="w-full" row="2" v-model="newDishName" />
          <Button
            link
            rounded
            :pt="{ root: { class: '!p-1.5 !w-8 !h-8' } }"
            :pt-options="{ mergeProps: true }"
            @click="isEditingName = false"
          >
            <template #icon>
              <ButtonClose />
            </template>
          </Button>
          <Button
            link
            rounded
            :pt="{ root: { class: '!py-1.5 !px-0 !w-12 !h-8' } }"
            :pt-options="{ mergeProps: true }"
            @click="editName"
          >
            <template #icon>
              <ButtonConfirm />
            </template>
          </Button>
        </div>
        <Menu
          ref="menu"
          id="overlay_menu"
          :model="items"
          :popup="true"
          :pt="{ submenuHeader: { class: '!p-0' } }"
          :pt-options="{ mergeProps: true }"
        />
        <input
          ref="fileInput"
          type="file"
          name="dish-image"
          id="dish-image"
          accept="image/jpeg, image/jpg, image/png, image/webp, image/heic"
          @change="uploadUserImage"
          class="hidden mb-4"
        />
      </div>
      <div v-if="dish && hasImage" class="flex justify-center my-5">
        <DishImage
          :dishId="dish.id"
          :type="dish.selected_image"
          :alt="dish.name"
          ref="dishImageRef"
          @click="isImageModalVisible = true"
        />
      </div>
      <div v-if="dish && dish.isPlanned" class="mb-6">
        <h3 class="mb-2">Planifié le :</h3>
        <ul class="flex flex-col gap-3">
          <li
            v-for="(plannedDish, index) in displayedPlannedDishes"
            :key="index"
            @click="router.push({ path: `/planning/planned-menu/${plannedDish.planned_menu_id}` })"
            class="cursor-pointer">
            <i class="pi pi-arrow-up-right"></i> {{ new Date(plannedDish.date).toLocaleString('fr-CH', {
              dateStyle: 'short',
            }) }} - {{ plannedDish.menu_name }}
          </li>
        </ul>
        <div class="mt-3">
          <p v-if="!showAllDates && dish.isPlanned.length > 3" @click="showAllDates = true" class="mb-10 inline-button">
            {{ t('menuDishView.planning.showMoreDates', {
              count: dish.isPlanned.length - 3, s: dish.isPlanned.length - 3
                > 1 ? 's' : ''
            }) }}
          </p>
          <p v-if="showAllDates" @click="showAllDates = false" class="mb-10 inline-button">
            {{ t('menuDishView.planning.showLessDates') }}
          </p>
        </div>
        <!-- <ul class="flex flex-col gap-3">
          <li v-for="(plannedDish, index) in dish.isPlanned" :key="index"
            @click="router.push({ path: `/planning/planned-menu/${plannedDish.planned_menu_id}` })"
            class="cursor-pointer">
            <i class="pi pi-arrow-up-right"></i> {{ new Date(plannedDish.date).toLocaleString('fr-CH', {
            dateStyle: 'short',
            }) }} - {{ plannedDish.menu_name }}
          </li>
        </ul> -->
      </div>
      <div class="flex flex-col gap-3">
        <div class="flex flex-col justify-between gap-1">
          <label class="text-sm font-semibold text-surface-700" for="numberOfPeopleInput">
            {{ t('menuDishView.numberOfPeople') }}
          </label>
          <InputNumber
            v-model="numberOfPeople"
            inputId="numberOfPeopleInput"
            showButtons
            buttonLayout="horizontal"
            :step="1"
            :min="1"
            :suffix="` ${t('menuDishView.numberOfPeopleSuffix', { count: numberOfPeople })}`"
            :disabled="showDishSkeleton"
          />
        </div>

        <DataTable
          :value="listIngredients"
          size="small"
          @row-click="(evt) => addOrUpdateIngredient(evt.data)"
        >
          <Column field="quantity" :header="t('profileFavorites.creationForm.quantityShort')">
            <template #body="slotProps">
              {{ slotProps.data.quantity > 0 ? slotProps.data.quantity : '' }}
            </template>
          </Column>
          <Column field="unit" :header="t('profileFavorites.creationForm.unit')">
            <template #body="slotProps">
              {{ t(`ingredients.unit.symbol.${slotProps.data.unit}`) }}
            </template>
          </Column>
          <Column field="name" :header="t('profileFavorites.creationForm.name')" />
          <Column field="price" :header="t('profileFavorites.creationForm.price')" />
        </DataTable>
        <div class="hover:cursor-pointer" @click="addOrUpdateIngredient(null)">
          <p>+ {{ t('profileFavorites.creationForm.addIngredientButton') }}</p>
        </div>
        <div class="flex flex-col items-end gap-4">
          <!-- <p class="font-bold">~ CHF {{ dish ? calculateDishPrice(dish, numberOfPeople) : 0 }}</p> -->
          <div class="flex justify-between w-full gap-4">
            <span
              >~ CHF {{ dish ? calculateDishPrice(dish, 1) : 0 }} /
              {{ t('menuDishView.nbPersonShort') }}</span
            >
            <span class="font-bold"
              >~ CHF {{ dish ? calculateDishPrice(dish, numberOfPeople) : 0 }}</span
            >
          </div>
          <Button
            class="col-span-1"
            :disabled="!dish || showDishSkeleton"
            @click="
              router.push({
                path: `/menu/dishes/${dishId}/add-to-orders`,
                query: { nbPeople: numberOfPeople },
              })
            "
          >
            <ButtonWithIconWrapper>
              <template #icon>
                <ButtonOrders />
              </template>
              <template #label>
                {{
                  dish?.hasIngredientsInShoppingList
                    ? t('menuDishView.editIngredientsList')
                    : t('menuDishView.addToIngredientsList')
                }}
              </template>
            </ButtonWithIconWrapper>
          </Button>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-2 my-4">
        <Button class="col-span-2" size="large" :disabled="!dish || showDishSkeleton" @click="addToWeekMenu">
          <ButtonWithIconWrapper>
            <template #icon>
              <NavPlanningIcon />
            </template>
            <template #label> {{ t('menuDishView.addtoWeekMenu') }} </template>
          </ButtonWithIconWrapper>
        </Button>
        <Button
          :disabled="!dish || showDishSkeleton"
          class="col-span-1"
          severity="secondary"
          @click="getImage(hasIaImage)"
        >
          <ButtonWithIconWrapper>
            <template #icon>
              <ButtonImage />
            </template>
            <template #label>
              {{ hasIaImage ? t('menuDishView.seeImage') : t('menuDishView.generateImage') }}
            </template>
          </ButtonWithIconWrapper>
        </Button>
        <Button
          :disabled="!dish || showDishSkeleton"
          class="col-span-1"
          severity="secondary"
          @click="getRecipe(dish?.hasRecipe ?? false, $event)"
        >
          <ButtonWithIconWrapper>
            <template #icon>
              <ButtonList />
            </template>
            <template #label>
              {{ dish?.hasRecipe ? t('menuDishView.seeRecipe') : t('menuDishView.generateRecipe') }}
            </template>
          </ButtonWithIconWrapper>
        </Button>
      </div>
    </div>
    <div v-if="generatingRecipe">
      <LoadingView :message="t('menuDishView.loadingRecipeLabel')" :generation="true" />
      <LoadingBar class="mt-20 mb-3" :durationInSeconds="25" />
    </div>
    <div v-if="generatingImage">
      <LoadingView :message="t('menuDishView.loadingImageLabel')" :generation="true" />
    </div>
    <EditIngredient
      v-model:ingredient="ingredientToEdit"
      :index="ingredientToEditIndex"
      v-model:visible="showSidebar"
      @add-ingredient="addIngredient"
      @edit-ingredient="updateIngredient"
      @delete-ingredient="deleteIngredients"
      showPrice
    />

    <AddDishToPlanningDialog v-model:visible="showAddToPlanningDialog" :dish="dish"
      @close-sidebar="showAddToPlanningDialog = false" />
  </main>
</template>
