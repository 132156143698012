<script setup lang="ts">
import Dropdown from 'primevue/dropdown'
import Calendar from 'primevue/calendar'
import Textarea from 'primevue/textarea'
import Column from 'primevue/column'
import DataTable from 'primevue/datatable'
import { onMounted, type Ref, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import Button from 'primevue/button'
import axios from '@/utils/axios'
import { useEnvStore } from '@/stores/envStore'
import { useAuth } from '@/composables/useAuth'
import type { Supplier } from '@/types/api'
import { useOrderList } from '@/stores/orderStore.js'
import { useToast } from 'primevue/usetoast'
import { useRouter } from 'vue-router'
import { useFetch } from '@/composables/useFetch.js'

const orderStore = useOrderList()
const ingredientsToOrder = orderStore.ingredientsToOrder
const { t } = useI18n<{ message: MessageSchema }>()
const { user } = useAuth()
const toast = useToast()
const router = useRouter()
const { isFetching, fetchData } = useFetch()

const supplierList: Ref<Supplier[]> = ref([])

const selectedSupplierUuid: Ref<string | null> = ref(null)
const wantedDate = ref(new Date())
const customMessage = ref('')

async function order() {
  if (user.value) {
    const restaurantId = user.value?.restaurants[0].id
    const { data, error } = await fetchData(
      useEnvStore().apiUrl + '/restaurants/' + restaurantId + '/order',
      'POST',
      {
        ingredients: ingredientsToOrder,
        wantedDate: wantedDate.value,
        customMessage: customMessage.value,
        supplierUuid: selectedSupplierUuid.value,
      }
    )

    if (error) {
      toast.add({
        severity: 'error',
        summary: 'Erreur lors de la création de la commande',
        detail: 'Veuillez réessayer plus tard.',
        life: 3000,
      })
    } else if (data) {
      toast.add({
        severity: 'success',
        summary: 'Commande réalisée avec succès !',
        detail: 'Vous avez reçu un email de confirmation.',
        life: 3000,
      })

      await orderStore.fetchIngredients(restaurantId)
      router.push({ name: 'orders' })
    }
  }
}

async function fetchSuppliers() {
  if (user.value) {
    const restaurantId = user.value?.restaurants[0].id
    try {
      const response = await axios.get(
        `${useEnvStore().apiUrl}/restaurants/${restaurantId}/suppliers`
      )
      supplierList.value = response.data
    } catch (error) {
      console.error('Erreur lors du chargement des fournisseurs', error)
    }
  }
}

onMounted(() => {
  fetchSuppliers()
})
</script>

<template>
  <main class="space-y-4">
    <div>
      <h3>{{ t('orders.orderForm.supplier') }}</h3>
      <Dropdown
        v-model="selectedSupplierUuid"
        :options="supplierList.filter((s) => s.email)"
        optionLabel="name"
        option-value="uuid"
        :placeholder="t('orders.orderForm.supplierPlaceholder')"
        class="w-full"
      />
    </div>

    <div>
      <h3>{{ t('orders.orderForm.wantedDate') }}</h3>
      <Calendar v-model="wantedDate" class="w-full" />
    </div>

    <div>
      <h3>{{ t('orders.orderForm.customMessage') }}</h3>
      <Textarea v-model="customMessage" class="w-full" />
    </div>

    <div>
      <h3>{{ t('orders.orderForm.shippingList') }}</h3>
      <DataTable :value="ingredientsToOrder">
        <Column field="quantity" :header="t('orders.orderForm.quantityShort')" />
        <Column field="unit" :header="t('orders.orderForm.unit')" />
        <Column field="name" :header="t('orders.orderForm.name')" />
      </DataTable>
    </div>

    <div>
      <Button
        :label="t('orders.orderForm.order')"
        @click="order"
        class="w-full"
        :disabled="!selectedSupplierUuid || !wantedDate || ingredientsToOrder.length === 0"
        :loading="isFetching"
      />
    </div>
  </main>
</template>
