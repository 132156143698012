import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref, computed, watch, watchEffect } from 'vue';
import type { PlannedMenu, MenuCourse } from '@/types/planning';
import { useFetch } from '@/composables/useFetch';
import { useToast } from 'primevue/usetoast';
import i18n, { type MessageSchema } from '@/i18n';
import { useEnvStore } from './envStore';
import type { Dish, DishTitleToPlan, DishToPlan } from '@/types/dish';
import { useDishesStore } from './dishesStore';
import { useAuth } from '@/composables/useAuth';
import { useI18n } from 'vue-i18n';



export const usePlanningStore = defineStore('planning', () => {
  const user = ref(useAuth().user)

  const plannedMenus = ref<PlannedMenu[]>(<PlannedMenu[]>[]);
  const selectedMenu = ref<PlannedMenu | null>(null);
  const selectedMenuCourse = ref<MenuCourse | null>(null);
  const selectedPlannedMenu = ref<PlannedMenu | null>(null);
  // const dishToPlanClipboard = ref<Dish | null>(null);
  const dishToPlanPlannedMenusIds = ref<number[]>([]);
  const dishToPlan = ref<DishToPlan | null>(null);
  const dishTitleToPlan = ref<DishTitleToPlan | null>(null);
  const shouldRefetch = ref<boolean>(false);
  const selectedWeek = ref(null)
  const choosingDishToPlan = ref<boolean>(false);

  const currentDate = ref(new Date());
  const currentWeekNumber = ref(getWeekNumber(currentDate.value));
  const weekRange = ref(getWeekRange(currentDate.value));
  const isLoading = ref(false);


  function getWeekNumber(date: Date): number {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  }

  watch(weekRange, async (newRange) => {
    // console.log("Change planning Store.weekrange")

    const restaurantId = user.value?.restaurants[0]?.id || null
    await fetchMenusIfNeeded(newRange.start.toISOString().split('T')[0], newRange.end.toISOString().split('T')[0], restaurantId);
  }, { deep: true, immediate: true });

  function getWeekRange(date: Date): { start: Date, end: Date } {
    const start = new Date(date);
    const dayOfWeek = start.getDay();
    const diffToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek; // If Sunday, go to Monday of the same week
    start.setDate(start.getDate() + diffToMonday);

    const end = new Date(start);
    end.setDate(start.getDate() + 6); // Get the following Sunday

    return { start, end };
  }

  function goToPreviousWeek() {
    currentDate.value.setDate(currentDate.value.getDate() - 7);
    currentWeekNumber.value = getWeekNumber(currentDate.value);
    weekRange.value = getWeekRange(currentDate.value);
  }

  function goToNextWeek() {
    currentDate.value.setDate(currentDate.value.getDate() + 7);
    currentWeekNumber.value = getWeekNumber(currentDate.value);
    weekRange.value = getWeekRange(currentDate.value);
  }

  const { fetchData } = useFetch();
  const toast = useToast();
  const { t } = i18n.global;

  function selectMenu(menuId: number) {
    const menu = plannedMenus.value.find(m => m.id === menuId);
    selectedMenu.value = menu || null;
  }

  function selectMenuCourse(courseId: number) {
    // Iterate over all planned menus
    for (const menu of plannedMenus.value) {
      // Find the course within the current menu that matches the courseId
      const course = menu.menu_courses.find(c => c.id === courseId);
      if (course) {
        // Set the selectedMenu and selectedMenuCourse if found
        selectedMenu.value = menu;
        selectedMenuCourse.value = course;
        return;
      }
    }

    // If no course is found, clear the selected menu and course
    selectedMenu.value = null;
    selectedMenuCourse.value = null;
  }

  function selectPlannedMenu(plannedMenuId: number) {
    const menu = plannedMenus.value.find(m => m.id === plannedMenuId);
    selectedPlannedMenu.value = menu || null;
  }

  async function addPlannedMenu(newMenu: PlannedMenu, restaurantId: string | null, router) {
    if (!restaurantId) return

    const tempId = Date.now();  // Using current timestamp as a temporary ID
    const newPlannedMenu: PlannedMenu = {
      ...newMenu,
      tempId: tempId  // Temporarily assign a unique ID
    };

    // Optimistically add the new menu to the store
    plannedMenus.value.push(newPlannedMenu);
    const payload = {
      ...newPlannedMenu,
      restaurant_id: restaurantId
    }
    try {
      const { data, error } = await fetchData(`${useEnvStore().apiUrl}/planning/planned-menus`, 'POST', payload);
      if (error) throw error;

      // Update the temporary item with the actual ID from the response
      const index = plannedMenus.value.findIndex(menu => menu.tempId === tempId);
      if (index !== -1) {
        plannedMenus.value[index] = { ...newPlannedMenu, ...data.planned_menu, tempId: null };
      }
      if (!dishToPlan.value && !dishTitleToPlan.value) {
        // Not in modal mode, proceed with the usual navigation
        router.push(`/planning/planned-menu/${plannedMenus.value[index].id}`);
      }

      toast.add({ severity: 'success', summary: t('common.success'), detail: t('planning.plannedMenu.add.success'), life: 1500, });
    } catch (error) {
      console.error('Failed to add planned menu', error);
      toast.add({ severity: 'error', summary: t('common.error'), detail: t('planning.plannedMenu.add.error'), life: 1500, });

      // Rollback if the POST fails
      plannedMenus.value = plannedMenus.value.filter(menu => menu.tempId !== tempId);
    }
  }

  async function deletePlannedMenu(plannedMenuId: number, router) {

    const menuIndex = plannedMenus.value.findIndex(menu => menu.id === plannedMenuId);
    if (menuIndex === -1) return;
    const menuToDelete = { ...plannedMenus.value[menuIndex] };
    plannedMenus.value.splice(menuIndex, 1);

    try {
      const { error } = await fetchData(`${useEnvStore().apiUrl}/planning/planned-menus/${plannedMenuId}`, 'DELETE');
      if (error) throw error;
      // If deletion was successful, clear the selectedPlannedMenu if it matches the deleted menu
      if (selectedPlannedMenu.value && selectedPlannedMenu.value.id === plannedMenuId) {
        selectedPlannedMenu.value = null;
      }
      toast.add({ severity: 'success', summary: t('common.success'), detail: t('planning.plannedMenu.delete.success'), life: 1500 });
      router.push({ path: '/planning' });
    } catch (error) {
      console.error('Failed to delete planned menu', error);
      toast.add({ severity: 'error', summary: t('common.error'), detail: t('planning.plannedMenu.delete.error'), life: 1500 });

      // Rollback if the DELETE fails: reinsert the menu into plannedMenus
      plannedMenus.value.splice(menuIndex, 0, menuToDelete);
    }
    // Optimistically remove the menu from the store
    // plannedMenus.value = plannedMenus.value.filter(menu => menu.id !== plannedMenuId);
    // if (selectedPlannedMenu.value && selectedPlannedMenu.value.id === plannedMenuId) {
    //   selectedPlannedMenu.value = null;
    // }
    // try {
    //   const { error } = await fetchData(`${useEnvStore().apiUrl}/planning/planned-menus/${plannedMenuId}`, 'DELETE');
    //   if (error) throw error;
    //   toast.add({ severity: 'success', summary: t('common.success'), detail: t('planning.plannedMenu.delete.success'), life: 1500, });
    // } catch (error) {
    //   console.error('Failed to delete planned menu', error);
    //   toast.add({ severity: 'error', summary: t('common.error'), detail: t('planning.plannedMenu.delete.error'), life: 1500, });
    //   // Rollback if the DELETE fails
    //   const { data } = await fetchData(`${useEnvStore().apiUrl}/planning/planned-menus/${plannedMenuId}`, 'GET');
    //   plannedMenus.value.push(data.planned_menu);
    // }
  }

  async function planDish(dish: Dish, selectedPlannedMenuIds: number[], dishType: string) {

    const { fetchData } = useFetch();
    const dishTypeMap = { 'starter': 1, 'main': 2, 'dessert': 3 };
    const dishTypeValue = dishTypeMap[dishType];
    const dishesStore = useDishesStore();

    if (!dishTypeValue) {
      throw new Error("Invalid dish type");
    }

    const payload = {
      planned_menu_ids: selectedPlannedMenuIds,
      dish_type: dishTypeValue
    };

    const { data, error } = await fetchData(
      `${useEnvStore().apiUrl}/dishes/${dish.id}/plan`,
      'POST',
      payload
    );

    if (error) {
      throw error;
    }
    // console.log("GOT THE DATA : ", data)
    // Mise à jour de l'état local avec les données retournées
    data.menu_courses.forEach((newCourse: MenuCourse) => {
      // console.log("For each menu Course")
      const menuIndex = plannedMenus.value.findIndex(menu => menu.id === newCourse.planned_menu_id);
      // console.log("menuIndex : ", menuIndex)
      if (menuIndex !== -1) {
        const courseIndex = plannedMenus.value[menuIndex].menu_courses.findIndex(course => course.id === newCourse.id);
        // console.log("courseIndex : ", courseIndex)
        if (courseIndex !== -1) {
          plannedMenus.value[menuIndex].menu_courses[courseIndex] = {
            ...plannedMenus.value[menuIndex].menu_courses[courseIndex],
            ...newCourse,
            dish: dish
          };
        } else {
          plannedMenus.value[menuIndex].menu_courses.push({
            ...newCourse,
            dish: dish
          });
        }
        if (selectedPlannedMenu.value && selectedPlannedMenu.value.id === newCourse.planned_menu_id) {
          const selectedCourseIndex = selectedPlannedMenu.value.menu_courses.findIndex(course => course.id === newCourse.id);
          if (selectedCourseIndex !== -1) {
            selectedPlannedMenu.value.menu_courses[selectedCourseIndex] = {
              ...selectedPlannedMenu.value.menu_courses[selectedCourseIndex],
              ...newCourse,
              dish: dish
            };
          } else {
            // console.log("Selected course index === -1 so we push. ");
            selectedPlannedMenu.value.menu_courses.push({
              ...newCourse,
              dish: dish
            });
          }
        }
      }
    });

    dishesStore.shouldRefetch = true;

    return data;
  }

  async function planDishTitle(dishTitle: string, selectedPlannedMenuIds: number[], dishType: string) {

    const { fetchData } = useFetch();
    const dishTypeMap = { 'starter': 1, 'main': 2, 'dessert': 3 };
    const dishTypeValue = dishTypeMap[dishType];

    if (!dishTypeValue) {
      throw new Error("Invalid dish type");
    }

    const payload = {
      planned_menu_ids: selectedPlannedMenuIds,
      dish_type: dishTypeValue,
      dish_title: dishTitle
    };

    const { data, error } = await fetchData(
      `${useEnvStore().apiUrl}/planning/plan-title`,
      'POST',
      payload
    );

    if (error) {
      throw error;
    }
    data.menu_courses.forEach((newCourse: MenuCourse) => {
      // console.log("For each menu Course")
      const menuIndex = plannedMenus.value.findIndex(menu => menu.id === newCourse.planned_menu_id);
      // console.log("menuIndex : ", menuIndex)
      if (menuIndex !== -1) {
        const courseIndex = plannedMenus.value[menuIndex].menu_courses.findIndex(course => course.id === newCourse.id);
        // console.log("courseIndex : ", courseIndex)
        if (courseIndex !== -1) {
          plannedMenus.value[menuIndex].menu_courses[courseIndex] = {
            ...plannedMenus.value[menuIndex].menu_courses[courseIndex],
            ...newCourse,
            name: dishTitle
          };
        } else {
          plannedMenus.value[menuIndex].menu_courses.push({
            ...newCourse,
            name: dishTitle
          });
        }
        if (selectedPlannedMenu.value && selectedPlannedMenu.value.id === newCourse.planned_menu_id) {
          const selectedCourseIndex = selectedPlannedMenu.value.menu_courses.findIndex(course => course.id === newCourse.id);
          if (selectedCourseIndex !== -1) {
            selectedPlannedMenu.value.menu_courses[selectedCourseIndex] = {
              ...selectedPlannedMenu.value.menu_courses[selectedCourseIndex],
              ...newCourse,
              name: dishTitle
            };
          } else {
            // console.log("Selected course index === -1 so we push. ");
            selectedPlannedMenu.value.menu_courses.push({
              ...newCourse,
              name:dishTitle
            });
          }
        }
      }
    });

    // dishesStore.shouldRefetch = true;

    return data;
  }

  // Fonction principale qui gère le reste des actions et appelle `performPlanDish`
  // async function planDish(router, toast) {
  //   isLoading.value = true;
  //   const dishesStore = useDishesStore();

  //   if (!dishToPlan.value) {
  //     console.error("dishToPlan is null or undefined");
  //     toast.add({
  //       severity: 'error',
  //       summary: t('common.error'),
  //       detail: t('planning.plannedMenu.planDish.error.noDishToPlan'),
  //       life: 1500,
  //     });
  //     isLoading.value = false;
  //     return;
  //   }

  //   const { clipboardDish, selectedPlannedMenuIds, dishType } = dishToPlan.value;

  //   if (!clipboardDish || selectedPlannedMenuIds.length === 0 || !dishType) {
  //     console.error("Missing required fields for planning a dish");
  //     toast.add({
  //       severity: 'error',
  //       summary: t('common.error'),
  //       detail: t('planning.plannedMenu.planDish.error.missingFields'),
  //       life: 1500,
  //     });
  //     isLoading.value = false;
  //     return;
  //   }

  //   try {
  //     const data = await planDish(clipboardDish, selectedPlannedMenuIds, dishType);



  //     if (router.currentRoute.value.path.startsWith('/menu/dishes/')) {
  //       if (data.new_dish) {
  //         await router.replace({ path: `/menu/dishes/${data.new_dish.id}` });
  //         dishesStore.selectedDish = data.new_dish;
  //       } else {
  //         dishesStore.selectedDish = data.dish;
  //       }
  //     }

  //     dishToPlan.value = null;

  //     toast.add({
  //       severity: 'success',
  //       summary: t('common.success'),
  //       detail: t('planning.plannedMenu.planDish.success'),
  //       life: 1500,
  //     });

  //     if (router.currentRoute.value.path.startsWith('/menu/dishes/')) {
  //       router.push('/planning');
  //     }
  //   } catch (error) {
  //     console.error('Failed to plan dish', error);
  //     toast.add({
  //       severity: 'error',
  //       summary: t('common.error'),
  //       detail: t('planning.plannedMenu.planDish.error.failed'),
  //       life: 1500,
  //     });
  //   } finally {
  //     isLoading.value = false;
  //   }
  // }

  async function addOrUpdateMenuCourse(course: MenuCourse, router) {
    if (course.id === -1) {
      // It's a new course, so create it
      await createMenuCourse(course, router);
    } else {
      // It's an existing course, so update it
      await updateMenuCourse(course);
    }
  }


  async function createMenuCourse(course: MenuCourse, router) {
    if (!selectedPlannedMenu.value) return;
    const tempId = Date.now();
    const optimisticCourse: MenuCourse = {
      ...course,
      id: tempId,// Temporary ID until confirmed by the backend
    };

    // Optimistically add the course to both the selected menu and the general planned menus array
    selectedPlannedMenu.value.menu_courses.push(optimisticCourse);

    // Find the corresponding planned menu in the main array and update it as well
    // const plannedMenuIndex = plannedMenus.value.findIndex(m => m.id === selectedPlannedMenu.value?.id);
    // console.log("PLANNEDMENU INDEX ", plannedMenuIndex);
    // if (plannedMenuIndex !== -1) {
    //   plannedMenus.value[plannedMenuIndex].menu_courses.push(optimisticCourse);
    // }

    try {
      const payload = {
        ...course,
        dish: course.dish?.id ?? null
      };
      const { data, error } = await fetchData(`${useEnvStore().apiUrl}/planning/planned-menus/${course.planned_menu_id}/menu-courses`, 'POST', payload);
      if (error) throw error;

      const index = selectedPlannedMenu.value?.menu_courses.findIndex(c => c.id === tempId);
      // console.log("Check if data.new_dish" + data.new_dish);
      const dishesStore = useDishesStore();
      if (data.new_dish) {
        optimisticCourse.dish = data.new_dish;

        if (router.currentRoute.value.path.startsWith('/menu/dishes/')) {
          // If a new dish was created, update the dish in the course
          // dishesStore.dishes.push(...dishesStore.dishes, data.new_dish);
          if (dishToPlan.value)
            dishToPlan.value.clipboardDish = data.new_dish;

          dishesStore.selectedDish = data.new_dish;
          router.replace({ path: `/menu/dishes/${data.new_dish.id}` });
        }

      }
      shouldRefetch.value = true;
      dishesStore.shouldRefetch = true;
      if (dishesStore.selectedDish) {
        const { data: dishResponse, error } = await fetchData(`${useEnvStore().apiUrl}/dishes/${dishesStore.selectedDish.id}`, 'GET');
        if (error) throw error;
        dishesStore.selectedDish = dishResponse;
      }
      console.log("INDEX : ", index)
      if (index !== -1 && selectedPlannedMenu.value) {
        selectedPlannedMenu.value.menu_courses[index] = { ...optimisticCourse, ...data.menu_course };

        // Also update in the plannedMenus array
        // const menuIndex = plannedMenus.value.findIndex(m => m.id === selectedPlannedMenu.value?.id);
        // if (menuIndex !== -1) {
        //   const courseIndex = plannedMenus.value[menuIndex].menu_courses.findIndex(c => c.id === tempId);
        //   if (courseIndex !== -1) {
        //     plannedMenus.value[menuIndex].menu_courses[courseIndex] = { ...optimisticCourse, ...data.menu_course };
        //   }
        // }
      }

      toast.add({ severity: 'success', summary: t('common.success'), detail: t('planning.plannedMenu.menuCourse.create.success'), life: 1500, });
    } catch (error) {
      console.error('Failed to create menu course', error);
      toast.add({ severity: 'error', summary: t('common.error'), detail: t('planning.plannedMenu.menuCourse.create.error'), life: 1500, });

      // Rollback if the POST fails
      if (selectedPlannedMenu.value) {
        selectedPlannedMenu.value.menu_courses = selectedPlannedMenu.value.menu_courses.filter(c => c.id !== tempId);
        // Also rollback in the plannedMenus array
        // const menuIndex = plannedMenus.value.findIndex(m => m.id === selectedPlannedMenu.value?.id);
        // if (menuIndex !== -1) {
        //   plannedMenus.value[menuIndex].menu_courses = plannedMenus.value[menuIndex].menu_courses.filter(c => c.id !== tempId);
        // }
      }
    }
  }

  async function updateMenuCourse(course: MenuCourse) {
    if (!selectedPlannedMenu.value) return;

    // Find the original course in the selectedPlannedMenu
    const originalCourseIndex = selectedPlannedMenu.value.menu_courses.findIndex(c => c.id === course.id);

    console.log("Original COURSE INDEX ", originalCourseIndex);
    if (originalCourseIndex === -1) return;  // If the course doesn't exist, exit the function

    // Save the original course data for potential rollback
    const originalCourse = { ...selectedPlannedMenu.value.menu_courses[originalCourseIndex] };

    // Optimistically update the course in the selectedPlannedMenu
    selectedPlannedMenu.value.menu_courses[originalCourseIndex] = { ...course };

    // Find and update the course in the main plannedMenus array
    const plannedMenuIndex = plannedMenus.value.findIndex(m => m.id === selectedPlannedMenu.value?.id);
    if (plannedMenuIndex !== -1) {
      const courseIndex = plannedMenus.value[plannedMenuIndex].menu_courses.findIndex(c => c.id === course.id);
      if (courseIndex !== -1) {
        plannedMenus.value[plannedMenuIndex].menu_courses[courseIndex] = { ...course };
      }
    }

    try {
      const payload = {
        ...course,
        dish: course.dish?.id ?? null
      };
      const { data, error } = await fetchData(`${useEnvStore().apiUrl}/planning/planned-menus/${course.planned_menu_id}/menu-courses/${course.id}`, 'PATCH', payload);
      if (error) throw error;

      // Confirm the update with data from the server
      console.log("DATA from function updateMenuCourse", data)
      selectedPlannedMenu.value.menu_courses[originalCourseIndex] = { ...data.menu_course };
      console.log("SELECTED PLANNED MENU ", selectedPlannedMenu.value);
      if (plannedMenuIndex !== -1) {
        const courseIndex = plannedMenus.value[plannedMenuIndex].menu_courses.findIndex(c => c.id === course.id);
        if (courseIndex !== -1) {
          plannedMenus.value[plannedMenuIndex].menu_courses[courseIndex] = { ...data.menu_course };
        }
      }

      toast.add({ severity: 'success', summary: t('common.success'), detail: t('planning.plannedMenu.menuCourse.update.success'), life: 1500, });
    } catch (error) {
      console.error('Failed to update menu course', error);
      toast.add({ severity: 'error', summary: t('common.error'), detail: t('planning.plannedMenu.menuCourse.update.error'), life: 1500, });

      // Rollback to the original course if the PATCH fails
      selectedPlannedMenu.value.menu_courses[originalCourseIndex] = originalCourse;
      if (plannedMenuIndex !== -1) {
        const courseIndex = plannedMenus.value[plannedMenuIndex].menu_courses.findIndex(c => c.id === course.id);
        if (courseIndex !== -1) {
          plannedMenus.value[plannedMenuIndex].menu_courses[courseIndex] = originalCourse;
        }
      }
    }
  }

  async function deleteMenuCourse(courseId: number) {
    if (!selectedPlannedMenu.value) return;

    // Find and store the course for possible rollback
    const courseIndex = selectedPlannedMenu.value.menu_courses.findIndex(c => c.id === courseId);
    if (courseIndex === -1) return; // Course not found

    const courseToDelete = selectedPlannedMenu.value.menu_courses[courseIndex];

    // Optimistically remove the course from the selectedPlannedMenu
    selectedPlannedMenu.value.menu_courses.splice(courseIndex, 1);

    // Also remove from the general plannedMenus array if needed
    const plannedMenuIndex = plannedMenus.value.findIndex(m => m.id === selectedPlannedMenu.value?.id);
    let courseIndexInMainArray = -1;
    if (plannedMenuIndex !== -1) {
      courseIndexInMainArray = plannedMenus.value[plannedMenuIndex].menu_courses.findIndex(c => c.id === courseId);
      if (courseIndexInMainArray !== -1) {
        plannedMenus.value[plannedMenuIndex].menu_courses.splice(courseIndexInMainArray, 1);
      }
    }

    try {
      const { error } = await fetchData(`${useEnvStore().apiUrl}/planning/planned-menus/${selectedPlannedMenu.value.id}/menu-courses/${courseId}`, 'DELETE');
      if (error) throw error;

      toast.add({ severity: 'success', summary: t('common.success'), detail: t('planning.plannedMenu.menuCourse.delete.success'), life: 1500 });
    } catch (error) {
      console.error('Failed to delete menu course', error);
      toast.add({ severity: 'error', summary: t('common.error'), detail: t('planning.plannedMenu.menuCourse.delete.error'), life: 1500 });

      // Rollback - reinsert the course on failure
      if (selectedPlannedMenu.value) {
        selectedPlannedMenu.value.menu_courses.splice(courseIndex, 0, courseToDelete);
      }
      if (plannedMenuIndex !== -1 && courseIndexInMainArray !== -1) {
        plannedMenus.value[plannedMenuIndex].menu_courses.splice(courseIndexInMainArray, 0, courseToDelete);
      }
    }
  }


  async function fetchMenusByDateRange(startDate: string, endDate: string, restaurantId: string | null) {
    isLoading.value = true;
    const { fetchData } = useFetch(); // Create fetchData here


    try {
      const url = `${useEnvStore().apiUrl}/planning/menus?restaurant_id=${restaurantId}&start_date=${startDate}&end_date=${endDate}`
      const { data, error } = await fetchData(url, 'GET');
      if (error) {
        toast.add({ severity: 'error', summary: t('common.error'), detail: t('planning.fetchMenusByDateRange.error'), life: 1500, });
        throw new Error('Failed to fetch menus by date range');
      }
      const fetchedMenus = data.planned_menus as PlannedMenu[];

      fetchedMenus.forEach(menu => {
        menu.date = new Date(menu.date).toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'
      });

      const existingMenus = plannedMenus.value;

      // Update existing menus or add new ones
      fetchedMenus.forEach(fetchedMenu => {
        const index = existingMenus.findIndex(menu => menu.id === fetchedMenu.id);
        if (index !== -1) {
          // Replace the existing menu if dates match
          existingMenus[index] = fetchedMenu;
        } else {
          // Add the new menu if no matching date is found
          existingMenus.push(fetchedMenu);
        }
      });

      // Update the reactive property to trigger updates in the Vue component
      plannedMenus.value = [...existingMenus];

      // console.log(plannedMenus.value[0].date); // Log to confirm changes
    } catch (error) {
      console.error('Failed to fetch menus by date range', error);
    } finally {
      isLoading.value = false;
    }
  }

  function getMenusByDate(date: string): PlannedMenu[] {
    // console.log("Get menu by date");
    const targetDate = new Date(date).toISOString().split('T')[0]; // Ensures comparison is only by date
    // console.log("planned Menus", plannedMenus.value)
    // console.log("Target Date", targetDate)
    return plannedMenus.value.filter(menu => {
      const menuDate = new Date(menu.date).toISOString().split('T')[0];
      // console.log("MenuDate", menuDate)
      return menuDate === targetDate;
    });
  }


  async function fetchMenusIfNeeded(startDate: string, endDate: string, restaurantId: string | null) {
    if (!restaurantId) return;
    // Check if data for the given range is already loaded
    const isDataLoaded = plannedMenus.value.some(menu =>
      new Date(menu.date) >= new Date(startDate) && new Date(menu.date) <= new Date(endDate)
    );

    if (!isDataLoaded || shouldRefetch.value) {
      console.log("Fetch data online")
      await fetchMenusByDateRange(startDate, endDate, restaurantId);
      shouldRefetch.value = false;
    } else {
      console.log("Fetch data from local")
    }
  }

  watch(shouldRefetch, async (newValue) => {
    if (newValue) {
      console.log("Refetch plannedMenus")
      plannedMenus.value.sort((a, b) => a.date.localeCompare(b.date));
      const restaurantId = user.value?.restaurants[0]?.id || null;
      const startDate = plannedMenus.value[0].date;
      const endDate = plannedMenus.value[plannedMenus.value.length - 1].date;
      plannedMenus.value = []; // empty the plannedMenus array
      await fetchMenusByDateRange(startDate, endDate, restaurantId);
      shouldRefetch.value = false;
    }
  });

  async function updateMenu(menu: PlannedMenu) {
    console.log("TODO :update menu");
    // try {
    //   const { data, error } = await fetchData(`/api/planned_menus/${menu.id}`, 'PATCH', menu);
    //   if (error) throw error;
    //   // update the local data
    //   const index = plannedMenus.value.planned_menus.findIndex(m => m.id === menu.id);
    //   if (index !== -1) plannedMenus.value.planned_menus[index] = data;
    //   toast.add({ severity: 'success', summary: t('update.success'), detail: t('plannedMenu.update.success') });
    // } catch (error) {
    //   console.error('Failed to update planned menu', error);
    //   toast.add({ severity: 'error', summary: t('update.error'), detail: t('plannedMenu.update.error') });
    // }
  }

  async function updatePlannedMenu(plannedMenu: PlannedMenu) {
    if (!plannedMenu) return;

    // Find the index of the planned menu to be updated
    const index = plannedMenus.value.findIndex(menu => menu.id === plannedMenu.id);
    if (index === -1) return;  // If the menu is not found, do nothing

    // Save the original state of the planned menu before making changes
    const originalPlannedMenu = { ...plannedMenus.value[index] };

    // Optimistically update the local state with the new planned menu
    plannedMenus.value[index] = plannedMenu;

    try {
      // Send the PATCH request to the server
      const { error } = await fetchData(
        `${useEnvStore().apiUrl}/planning/planned-menus/${plannedMenu.id}`,
        'PATCH',
        plannedMenu
      );

      if (error) throw error;

      // Update the planned menu in the store with the response data
      // plannedMenus.value[index] = data;

      // Notify the user of success
      toast.add({
        severity: 'success',
        summary: t('common.success'),
        detail: t('planning.plannedMenu.update.success'),
        life: 1500,
      });
    } catch (error) {
      console.error('Failed to update planned menu', error);

      // Rollback to the original state if there's an error
      plannedMenus.value[index] = originalPlannedMenu;

      // Notify the user of failure
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: t('planning.plannedMenu.update.error'),
        life: 1500,
      });
    }
  }

  watch(dishToPlan, (newValue) => {
    if (newValue) {
      // console.log("dishToPlan changed", newValue);
    }
  });


  return {
    plannedMenus,
    selectedMenu,
    selectedMenuCourse,
    selectedPlannedMenu,
    selectedWeek,
    currentDate,
    currentWeekNumber,
    weekRange,
    isLoading,
    choosingDishToPlan,
    goToNextWeek,
    goToPreviousWeek,
    addPlannedMenu,
    updatePlannedMenu,
    selectPlannedMenu,
    deletePlannedMenu,
    fetchMenusByDateRange,
    selectMenu,
    selectMenuCourse,
    addOrUpdateMenuCourse,
    createMenuCourse,
    updateMenuCourse,
    deleteMenuCourse,
    getMenusByDate,
    updateMenu,
    fetchMenusIfNeeded,
    // dishToPlanClipboard,
    dishToPlan,
    dishTitleToPlan,
    planDish,
    planDishTitle,
    shouldRefetch
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePlanningStore, import.meta.hot));
}
