import i18n from '@/i18n'
import { useEnvStore } from '@/stores/envStore'
import type { User, UserLoginRequest, UserRegisterRequest } from '@/types/api'
import axios from '@/utils/axios'
import { useToast } from 'primevue/usetoast'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

const errors = ref<{ [key: string]: string[] }>({})

const isFetching = ref(false)

const user = ref<User | null>(null)

export const useAuth = () => {
  const toast = useToast()
  const router = useRouter()

  const { t } = i18n.global

  async function initUser() {
    user.value = await getUser()
  }

  async function getUser(): Promise<User | null> {
    if (user.value) return user.value
    try {
      const response = await axios.get(useEnvStore().apiUrl + '/me')
      const user = response.data as User

      return {
        ...user,
        created_at: new Date(user.created_at),
        updated_at: new Date(user.updated_at),
        email_verified_at: user.email_verified_at ? new Date(user.email_verified_at) : undefined,
        two_factor_confirmed_at: user.two_factor_confirmed_at
          ? new Date(user.two_factor_confirmed_at)
          : undefined,
      }
    } catch (error) {
      return null
    }
  }

  async function login(userForm: UserLoginRequest, rememberMe: boolean, password: string) {
    isFetching.value = true

    // Patch primevue password component reactivity issue
    userForm.password = password
    userForm.remember = rememberMe

    // Sanctum CSRF
    if (!(await getCSRFCookie())) {
      isFetching.value = false
      return
    }

    // Login
    await axios
      .post(useEnvStore().apiUrl + '/login', userForm)
      .then(async (response) => {
        user.value = await getUser()
        console.log('response: ', response)
        toast.add({
          severity: 'success',
          summary: t('common.success'),
          detail: `${t('common.welcome')} ${user.value?.first_name} !`,
          life: 3000,
        })
        router.push({ name: 'home' })
      })
      .catch((error) => {
        if (error.response.data.errors) errors.value = error.response.data.errors
      })

    isFetching.value = false
  }

  async function register(
    userForm: UserRegisterRequest,
    password: string,
    passwordConfirmation: string,
    toast: any
  ) {
    isFetching.value = true

    // Patch primevue password component reactivity issue
    userForm.password = password
    userForm.password_confirmation = passwordConfirmation

    // Sanctum CSRF
    if (!(await getCSRFCookie())) {
      isFetching.value = false
      return
    }

    // Register
    await axios
      .post(useEnvStore().apiUrl + '/register', userForm)
      .then(() => {
        toast.add({
          severity: 'success',
          summary: t('common.success'),
          detail: t('auth.registration.success'),
          life: 3000,
        })
        router.push({ path: '/' })
      })
      .catch((error) => {
        if (error.response.data.errors) errors.value = error.response.data.errors
        const errorMessage = error.response.data.message ?? t('auth.registration.error.message')
        toast.add({ severity: 'error', summary: t('common.error'), detail: errorMessage, life: 1500, });
      })

    isFetching.value = false
  }

  async function logout() {
    console.log('logout')
    isFetching.value = true
    await axios.post(useEnvStore().apiUrl + '/logout')
    user.value = null
    router.push({ name: 'login' })
    isFetching.value = false
  }

  async function getCSRFCookie() {
    try {
      await axios.get(useEnvStore().apiUrl + '/sanctum/csrf-cookie')
      return true
    } catch (error) {
      console.log('error fetching CSRF cookie: ', error)
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: t('registration.error.message'),
        life: 3000,
      })
      return false
    }
  }

  async function sendResetLink(email: string) {
    isFetching.value = true

    await axios
      .post(useEnvStore().apiUrl + '/forgot-password', { email: email })
      .then(() => {
        toast.add({
          severity: 'success',
          summary: t('common.success'),
          detail: `Un lien de récupération a été envoyé à ${email}`,
          life: 3000,
        })
      })
      .catch((error) => {
        if (error.response.data.errors) errors.value = error.response.data.errors
      })

    isFetching.value = false
  }

  async function resetPassword(
    email: string,
    password: string,
    password_confirmation: string,
    token: string
  ) {
    isFetching.value = true

    await axios
      .post(useEnvStore().apiUrl + '/reset-password', {
        email: email,
        password: password,
        password_confirmation: password_confirmation,
        token: token,
      })
      .then(() => {
        toast.add({
          severity: 'success',
          summary: t('common.success'),
          detail: `Mot de passe mis à jour`,
          life: 3000,
        })
      })
      .catch((error) => {
        if (error.response.data.errors) errors.value = error.response.data.errors
      })

    isFetching.value = false
  }

  function redirectHome() {
    console.log(router)
    router.push({ name: 'home' })
  }

  return {
    login,
    register,
    logout,
    errors,
    isFetching,
    user,
    initUser,
    sendResetLink,
    resetPassword,
    redirectHome,
  }
}
