import DishCreationFormView from '@/views/Profile/DishCreationFormView.vue'
import MyProfileView from '@/views/Profile/MyProfileView.vue'
import SuppliersView from '@/views/Profile/SuppliersView.vue'
import type { RouteRecordRaw } from 'vue-router'

const profileRoutes: RouteRecordRaw[] = [
  {
    path: '/profile',
    name: 'profile',
    meta: {
      headerConfig: {
        headerLabel: 'profile',
        hide: false,
        showBack: true,
        showProfile: false,
      },
      footerConfig: {
        hide: true,
      },
      securityConfig: {
        requireAuthentication: true,
        rolesAllowed: ['common', 'admin'],
      },
      containerMargins: true,
    },
    component: MyProfileView,
  },
  {
    path: '/profile/add-edit-recipe',
    name: 'dish-creation-edition-form',
    meta: {
      headerConfig: {
        headerLabel: 'profile',
        hide: false,
        showBack: true,
        showProfile: false,
      },
      footerConfig: {
        hide: true,
      },
      securityConfig: {
        requireAuthentication: true,
        rolesAllowed: ['common', 'admin'],
      },
      containerMargins: true,
    },
    props: (route) => ({ dishEdition: route.query.edit === 'true' ? true : false }),
    component: DishCreationFormView,
  },
  {
    path: '/profile/my-profile',
    name: 'my-profile',
    meta: {
      headerConfig: {
        headerLabel: 'myProfile',
        hide: false,
        showBack: true,
        showProfile: false,
      },
      footerConfig: {
        hide: true,
      },
      securityConfig: {
        requireAuthentication: true,
        rolesAllowed: ['common', 'admin'],
      },
      containerMargins: true,
    },
    component: MyProfileView,
  },
  {
    path: '/profile/suppliers',
    name: 'suppliers',
    meta: {
      headerConfig: {
        headerLabel: 'suppliers',
        hide: false,
        showBack: true,
        showProfile: false,
      },
      footerConfig: {
        hide: true,
      },
      securityConfig: {
        requireAuthentication: true,
        rolesAllowed: ['common', 'admin'],
      },
      containerMargins: true,
    },
    component: SuppliersView,
  },
]

export default profileRoutes
