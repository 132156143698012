import MyRecipesView from '@/views/MyRecipes/MyRecipesView.vue'
import FavoritesView from '@/views/MyRecipes/FavoritesView.vue'
import GenerationHistoryView from '@/views/MyRecipes/GenerationHistoryView.vue'
import MyScansView from '@/views/MyRecipes/MyScansView.vue'
import type { RouteRecordRaw } from 'vue-router'
import PlannedView from '@/views/MyRecipes/PlannedView.vue'
import GeneratedTitlesView from '@/views/MyRecipes/GeneratedTitlesView.vue'


const myRecipesRoutes: RouteRecordRaw[] = [
  {
    path: '/my-recipes',
    name: 'my-recipes',
    meta: {
      headerConfig: {
        headerLabel: 'myRecipes',
        hide: false,
        showBack: false,
        showProfile: true,
      },
      footerConfig: {
        hide: false,
      },
      securityConfig: {
        requireAuthentication: true,
        rolesAllowed: ['common', 'admin'],
      },
      containerMargins: true,
    },
    component: MyRecipesView,
  },
  {
    path: '/my-recipes/generation-history',
    name: 'generation-history',
    meta: {
      headerConfig: {
        headerLabel: 'history',
        hide: false,
        showBack: true,
        showProfile: true,
      },
      footerConfig: {
        hide: false,
      },
      securityConfig: {
        requireAuthentication: true,
        rolesAllowed: ['common', 'admin'],
      },
      containerMargins: true,
    },
    component: GenerationHistoryView,
  },
  {
    path: '/my-recipes/my-scans',
    name: 'my-scans',
    meta: {
      headerConfig: {
        headerLabel: 'myScans',
        hide: false,
        showBack: true,
        showProfile: true,
      },
      footerConfig: {
        hide: false,
      },
      securityConfig: {
        requireAuthentication: true,
        rolesAllowed: ['common', 'admin'],
      },
      containerMargins: true,
    },
    component: MyScansView,
  },
  {
    path: '/my-recipes/favorites',
    name: 'favorites',
    meta: {
      headerConfig: {
        headerLabel: 'myFavorites',
        hide: false,
        showBack: true,
        showProfile: false,
      },
      footerConfig: {
        hide: true,
      },
      securityConfig: {
        requireAuthentication: true,
        rolesAllowed: ['common', 'admin'],
      },
      containerMargins: true,
    },
    component: FavoritesView,
  },
  {
    path: '/my-recipes/planned',
    name: 'planned',
    meta: {
      headerConfig: {
        headerLabel: 'planned',
        hide: false,
        showBack: true,
        showProfile: false,
      },
      footerConfig: {
        hide: true,
      },
      securityConfig: {
        requireAuthentication: true,
        rolesAllowed: ['common', 'admin'],
      },
      containerMargins: true,
    },
    component: PlannedView,
  },
  {
    path: '/my-recipes/titles',
    name: 'titles',
    meta: {
      headerConfig: {
        headerLabel: 'generatedTitles',
        hide: false,
        showBack: true,
        showProfile: false,
      },
      footerConfig: {
        hide: true,
      },
      securityConfig: {
        requireAuthentication: true,
        rolesAllowed: ['common', 'admin'],
      },
      containerMargins: true,
    },
    component: GeneratedTitlesView,
  },
]

export default myRecipesRoutes
