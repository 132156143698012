import ForgotPasswordView from '@/views/Auth/ForgotPasswordView.vue'
import FortifyHomeView from '@/views/Auth/FortifyHomeView.vue'
import LoginView from '@/views/Auth/LoginView.vue'
import RegisterView from '@/views/Auth/RegisterView.vue'
import ResetPasswordView from '@/views/Auth/ResetPasswordView.vue'
import type { RouteRecordRaw } from 'vue-router'

const authRoutes: RouteRecordRaw[] = [
  {
    path: '/register',
    name: 'register',
    meta: {
      headerConfig: {
        headerLabel: 'register',
        hide: false,
        showBack: false,
        showProfile: false,
      },
      footerConfig: {
        hide: true,
      },
      securityConfig: {
        requireAuthentication: false,
        rolesAllowed: ['common', 'admin'],
      },
      containerMargins: true,
    },
    component: RegisterView,
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      headerConfig: {
        headerLabel: 'login',
        hide: false,
        showBack: false,
        showProfile: false,
      },
      footerConfig: {
        hide: true,
      },
      securityConfig: {
        requireAuthentication: false,
        rolesAllowed: ['common', 'admin'],
      },
      containerMargins: true,
    },
    component: LoginView,
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    meta: {
      headerConfig: {
        headerLabel: 'forgotPassword',
        hide: false,
        showBack: false,
        showProfile: false,
      },
      footerConfig: {
        hide: true,
      },
      securityConfig: {
        requireAuthentication: false,
        rolesAllowed: ['common', 'admin'],
      },
      containerMargins: true,
    },
    component: ForgotPasswordView,
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    meta: {
      headerConfig: {
        headerLabel: 'resetPassword',
        hide: false,
        showBack: false,
        showProfile: false,
      },
      footerConfig: {
        hide: true,
      },
      securityConfig: {
        requireAuthentication: false,
        rolesAllowed: ['common', 'admin'],
      },
      containerMargins: true,
    },
    props: true,
    component: ResetPasswordView,
  },
  {
    path: '/fortify-home',
    name: 'fortify-home',
    meta: {
      headerConfig: {
        headerLabel: 'fortify-home',
        hide: false,
        showBack: false,
        showProfile: false,
      },
      footerConfig: {
        hide: true,
      },
      securityConfig: {
        requireAuthentication: true,
        rolesAllowed: ['common', 'admin'],
      },
      containerMargins: true,
    },
    beforeEnter: (to) => {
      if (to.query.verified === '1') {
        to.meta.headerConfig.headerLabel = 'emailVerified'
      } else {
        return { name: 'home' }
      }
    },
    component: FortifyHomeView,
  },
]

export default authRoutes
